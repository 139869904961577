import React from 'react';
import { Link } from 'react-router-dom';

const CommonHomePaheName = ({ homeLink, currentPage }) => {
    return (
        <ol className="breadcrumb">
            <li className="breadcrumb-item">
                <Link to={homeLink}>Home  </Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
                {currentPage}
            </li>
        </ol>
    );
};

export default CommonHomePaheName;

