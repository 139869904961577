import { ErrorMessage, Field, FieldArray, FormikProvider, useFormik, Form } from "formik";
import { Link, useNavigate } from "react-router-dom";
import * as jnb from "react-bootstrap";
import { useSelector } from "react-redux";
import React, { useState, useEffect } from "react";
import { BiTrash } from "react-icons/bi";
import { VscDiffAdded } from "react-icons/vsc";
import { CommonAxiosGet } from "../../../../CommonUtils/CommonAxios";
import { config } from "../../../../CommonUtils/CommonApis";
import allowNumbersOnly from "../../../../CommonUtils/CommonValidations";
import { viewBucketImage } from "../../../../CommonUtils/viewBucketImage";
import { BsEye } from "react-icons/bs";
import Swal from "sweetalert2";
import Sweetalert from "../../../../CommonUtils/SweetAlerts";
import CommonPost from "../../../../CommonUtils/CommonPost";
import * as  Yup from "yup";
export default function LevelInterviewEntryForm() {
    const [showInterviewDetails, setInterviewDetails] = useState([])
    const [showInterviewStatus, setInterviewStatus] = useState()
    const [showInterviewDetailsTable, setInterviewDetailsTable] = useState(false)
    const [showModelPopUp, setModelPopUp] = useState(false);
    const handleClose = () => {setModelPopUp(false);}
    const logindetails = useSelector((state) => state.reducers?.loginreducer?.userLoginDetials);
    const interviewLevelsformIk = useFormik({
        enableReinitialize: true,
        initialValues: { 
            status_of_interview: "",
            remarks_of_interview: "",
            applicant_id: "",
            slno: "",
            interviewcat: [{
                level: showInterviewStatus?.level_of_inerview,
                score: "",
                category: "",
                job_appl_id: showInterviewStatus?.applicant_id,
                post_id: showInterviewStatus?.technology_id,
                emp_id: logindetails?.username
            }]},
            validationSchema: Yup.object().shape({
                status_of_interview: Yup.string().required("Required"),
                remarks_of_interview: Yup.string().when("status_of_interview", {
                    is: (leave) => leave === "Not Through" ? true : false,
                    then: Yup.string().required("Required"), otherwise: Yup.string()
                }),
                interviewcat: Yup.array().of(Yup.object().shape({
                    score: Yup.string().required("Required"),
                    category: Yup.string().required(" Required"),
                }))
            }),
        onSubmit: (values) => {
            const updatedValues = {
                ...values,
                applicant_id: showInterviewStatus?.applicant_id,
                slno: showInterviewStatus?.slno,
                remarks_of_interview:values?.status_of_interview === "Through" ? "Verified" : values?.remarks_of_interview
            };
        
            Swal.fire({
                title: 'Are you sure you want to submit?',
                text: 'Please check it once before submitting',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Submit',
                cancelButtonText: 'Cancel',
                reverseButtons: true
            }).then((result) => {
                if (result.value) {
                    CommonPost.SaveAtLeavelInterviews(updatedValues).then((res) => {
                            if (res.data.ResponseCode === "01") {
                                Swal.fire({
                                    title: res?.data?.ResponseDesc,
                                    icon:"success",
                                    backdrop: false
                                }).then((res) => {
                                    if (res?.isConfirmed === true) {
                                        interviewLevelsformIk?.resetForm({
                                            values: {
                                                status_of_interview: "",
                                                remarks_of_interview: "",
                                                applicant_id: "",
                                                slno: "",
                                                interviewcat: [
                                                    {
                                                        level: showInterviewDetails?.level_of_inerview,
                                                        score: "",
                                                        category: "",
                                                        job_appl_id: showInterviewDetails?.applicant_id,
                                                        post_id: showInterviewDetails?.technology_id,
                                                        emp_id: logindetails?.username
                                                    }
                                                ]
                                            }
                                        });
                                    }
                                });
                            }
                            else if (res.data.ResponseCode === "02") {
                                Sweetalert(res.data.ResponseDesc, 'warning');
                            }
                            else if (res.data.ResponseCode === "04") {
                                Sweetalert(res.data.ResponseDesc);
                            }
                            else {
                                Swal.fire('Canceled', 'Something went wrong,Please check', 'error');
                            }
                        }).catch(() => {console.log("Exception Occured 71 ");
                        });
                } else if (result.dismiss === Swal.DismissReason.cancel) {
                    Swal.fire('Canceled', 'Do corrections and then retry :)', 'error');
                }
            })
        },
    });
    useEffect(() => {
        let Url = config.url.COMMON_URL + "getinterviewemployees/" + logindetails?.username;
        CommonAxiosGet(Url).then((res) => {
            if (res.data.status === true) {
                setInterviewDetails(res?.data?.getinterviewemployees) 
                setInterviewDetailsTable(false)
            }
            else { setInterviewDetails([]) 
                setInterviewDetailsTable(false)
            }
        })
    }, []);
    const [getUpdateInterviewStatus,setUpdateInterviewStatus]=useState()

    function  showStatusInModel(data){
        setModelPopUp(true);
        setUpdateInterviewStatus(data)
    }
    return (<>
        <jnb.Row className="px-3 mb-2 pt-3" >
            <jnb.Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <Link to="/homepage">Home</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">Update Interview Status</li>
                </ol>
            </jnb.Col>
            {showInterviewDetailsTable === true && 
            <jnb.Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
                <button type="button" class="btn btn-secondary  btn-sm float-end"
                    onClick={() => { setInterviewDetailsTable(false) }}>Back</button>
            </jnb.Col>}
        </jnb.Row>
       
        {showInterviewDetailsTable === true ? <>
        <jnb.Row className="px-3 mb-2 pt-3">
            <jnb.Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
                <div class="card rounded">
                    <div class="card-header " style={{ backgroundColor: '#d6eaf8', color: 'black', }}>Job Post Details</div>
                    <div class="card-body">
                        <jnb.Row>
                            <jnb.Col xs={12} sm={12} md={12} lg={4} xl={4} xxl={4}>
                                <label className="w-100"> <b>Technology:</b>&nbsp;&nbsp;</label>
                                     <i style={{ textAlign: "justify" }}>
                                    {showInterviewStatus?.zone_name}
                                </i>
                            </jnb.Col>
                            <jnb.Col xs={12} sm={12} md={12} lg={4} xl={4} xxl={4}>
                                <label className="w-100"><b>Designation:</b>&nbsp;&nbsp;</label>
                                <i style={{ textAlign: "justify" }}>
                                    {showInterviewStatus?.applied_desg}
                                </i>
                            </jnb.Col>
                            <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                                <label className="w-100"><b>Interview Level:</b>&nbsp;&nbsp;</label>
                                <i style={{ textAlign: "justify" }}>
                                    {showInterviewStatus?.level_of_inerview}
                                </i>
                            </jnb.Col>
                        </jnb.Row>
                    </div>
                </div>
            </jnb.Col>
            <jnb.Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
                <div class="card rounded">
                    <div class="card-header" style={{ backgroundColor: '#d5f5e3', color: 'black' }}>Candidate Details</div>
                    <div class="card-body">
                        <jnb.Row>
                            <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                                <label className="w-100"><b>Name:</b>&nbsp;&nbsp;  <i style={{ textAlign: "justify" }}>
                                    {showInterviewStatus?.candidate_name}
                                </i></label>
                            </jnb.Col>
                            <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                                <label className="w-100"><b> Years of Experience:&nbsp;&nbsp;<i style={{ textAlign: "justify" }}>
                                    {showInterviewStatus?.years_of_experience}
                                </i></b></label>
                            </jnb.Col>
                            <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                                <label className="w-100"><b>Resume: <i style={{ textAlign: "justify" }}>
                                    &nbsp;&nbsp; <button type="button" class="btn btn-primary btn-sm rounded" onClick={() => {
                                        let img = showInterviewStatus?.resume_upload
                                        viewBucketImage(img)
                                    }} ><BsEye /> </button>
                                </i> </b></label>
                               
                            </jnb.Col>
                        </jnb.Row>
                    </div>
                </div>
            </jnb.Col>
        </jnb.Row>
        <FormikProvider value={interviewLevelsformIk}>
            <Form onSubmit={interviewLevelsformIk.handleSubmit} onChange={interviewLevelsformIk.handleChange}>
                <Field type="hidden" name="technology" className="form-control" />
                <jnb.Row className="px-4 mb-2">
                <div className="border pt-1 rounded" style={{backgroundColor:"#faf7f1"}}>
                <jnb.Row className="px-3 pt-1">
                <jnb.Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
                    {/* <center> */}
                        {/* <div className="table-responsive"> style={{ width: "50%" }} */}
                            <table className="table table-condensed table-bordered table-striped table-responsive"
                    style={{ border: '1px solid lightblue', borderCollapse: 'collapse', }} >
                                <thead>
                                    <tr>
                                        <th>S.No</th>
                                        <th>Category</th>
                                        <th>Score</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody style={{ textAlign: "center" }}>
                                    <FieldArray name="interviewcat">
                                        {(formikFieldArray) => {
                                            return (
                                                formikFieldArray.form.values.interviewcat.map(
                                                    (qual, index) => {
                                                        return (
                                                            <>
                                                                <tr key={index}>
                                                                    <Field type="hidden" className="form-control" name={`[interviewcat[${index}].applicant_id`} ></Field>
                                                                    <td>{index + 1}</td>
                                                                    <td>
                                                                        <Field type="text" className="form-control" name={`[interviewcat[${index}].category`} ></Field>
                                                                        <ErrorMessage name={`[interviewcat[${index}].category`} component="div" className="text-danger" ></ErrorMessage>
                                                                    </td>
                                                                    <td> <Field type="text" className="form-control" name={`[interviewcat[${index}].score`} maxLength="2"
                                                                        onKeyPress={(e) => { allowNumbersOnly(e); }}></Field>
                                                                        <ErrorMessage name={`[interviewcat[${index}].score`} component="div" className="text-danger" ></ErrorMessage>
                                                                    </td>
                                                                    <td width="1"> {(index) ?
                                                                        <button type="button" className="button-titile btn btn-sm btn-danger rounded"
                                                                            onClick={() => { formikFieldArray.remove(index); }} >
                                                                            <BiTrash></BiTrash>
                                                                        </button> : ''}</td>
                                                                </tr>
                                                            </>
                                                        )
                                                    })
                                            )
                                        }}
                                    </FieldArray>
                                    <tr className='table-active'>
                                        <td align="right" colSpan={7}>
                                            <FieldArray name="interviewcat">
                                                {(formikFieldArray) => {
                                                    return formikFieldArray.form.values.interviewcat.map(
                                                        (LJP, index) => {
                                                            return (
                                                                <>
                                                                    {(index === 0) ? <>
                                                                        <button type="button" className="button-titile btn btn-sm btn-success rounded"
                                                                            onClick={() => {
                                                                                formikFieldArray.push({
                                                                                    level: showInterviewStatus?.level_of_inerview,
                                                                                    score: "",
                                                                                    category: "",
                                                                                    job_appl_id: showInterviewStatus?.applicant_id,
                                                                                    post_id: showInterviewStatus?.technology_id,
                                                                                    emp_id: logindetails?.username
                                                                                });
                                                                            }}
                                                                        >
                                                                            <VscDiffAdded></VscDiffAdded> </button> </> : ''}
                                                                </>
                                                            )
                                                        })
                                                }}
                                            </FieldArray>
                                        </td>
                                    </tr>
                                </tbody >
                            </table>
                            </jnb.Col>
                            <jnb.Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
                        <jnb.Row className="px-3 pt-4">
                        {/* <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}></jnb.Col> */}
                            <jnb.Col xs={12} sm={12} md={12} lg={4} xl={4} xxl={4}>
                                <jnb.InputGroup className="mb-4p5">
                                    <span className="label-text-style"><b>Final Status:<font style={{ color: "red" }}>*</font></b></span>
                                    <Field as="select" className='form-control' name="status_of_interview" 
                                    onChange={()=>{interviewLevelsformIk?.setFieldValue("remarks_of_interview","")}}>
                                        <option value="">--Select--</option>
                                        <option value="Through">Through</option>
                                        <option value="Not Through">Not Through</option>
                                    </Field>
                                    <ErrorMessage name="status_of_interview" component="div" className="text-danger" />
                                </jnb.InputGroup>
                            </jnb.Col>
                            {interviewLevelsformIk?.values?.status_of_interview === "Not Through" && 
                            <jnb.Col xs={12} sm={12} md={12} lg={4} xl={4} xxl={4}>
                                <jnb.InputGroup className="mb-4p5">
                                    <span className="label-text-style"><b>Remarks:<font style={{ color: "red" }}>*</font></b></span>
                                    <Field as="textarea" className="form-control mb-2" name="remarks_of_interview" style={{ height: '30px' }} maxLength="400"></Field>
                                    <ErrorMessage name="remarks_of_interview" component="div" className="text-danger" />
                                </jnb.InputGroup>
                            </jnb.Col>}
                        </jnb.Row>
                        <jnb.Row className="px-3">
                        <div className='d-flex flex-row justify-content-end pe-0'>
                                <button type="submit" className="btn btn-success btn-sm float-end ms-2 mb-4 rounded">Submit</button>
                            </div>
                        {/* <button type="submit" className="btn rounded-0 btn-success btn-sm" style={{ float: "right" }}>Submit</button></jnb.Col> */}
                        </jnb.Row>
                        </jnb.Col>
                        {/* </div> */}

                    {/* </center> */}
                </jnb.Row>
                </div>
                </jnb.Row>
            </Form>
        </FormikProvider></>:
        <> <jnb.Row className="px-3 pt-2">
         <table  className="table table-condensed table-bordered  table-responsive"
             style={{ border: '1px solid lightblue',borderCollapse: 'collapse' }}>
                 <thead>
                     <tr>
                         <th align="center">S.No</th>
                         <th>Technology</th>
                         <th>Designation</th>
                         <th>Interview Level</th>
                         <th>Candidate Name</th>
                         <th>Years of Experience</th>
                         <th>Resume</th>
                         <th>Update Interview Status</th>
                     </tr>
                 </thead>
                 <tbody style={{ textAlign: "left" }}>
                     {showInterviewDetails !== undefined && showInterviewDetails.map((data, i) => {
                        const colorstyle=data?.status_of_interview === "Through" ?"green" :"red"
                         return (
                         < >
                             <tr key={i}>
                                 <td width="100px" align="center">{i + 1}</td>
                                 <td>{data.zone_name}</td>
                                 <td>{data.applied_desg}</td>
                                 <td>{data.level_of_inerview}</td>
                                 <td>{data.candidate_name}</td>
                                 <td>{data.years_of_experience}</td>
                                 <td>
                                   
                                    <button type="button" class="btn btn-primary btn-sm rounded" onClick={() => {
                                        let img = data?.resume_upload
                                        viewBucketImage(img)
                                    }} ><BsEye /> </button></td>
                                <td align="center">

                                 {data?.status_of_interview === null ? <button type="button" className=" btn btn-outline-primary btn-sm  bg-opacity-50 rounded"
                                 onClick={() => {setInterviewDetailsTable(true);setInterviewStatus(data)}}>start</button> : 
                                 <button type="button" className=" btn btn-link btn-sm"
                                 onClick={() => {showStatusInModel(data)}}><b style={{color:colorstyle}}>
                                    {data?.status_of_interview}</b></button>}
                                 </td>
                             </tr>
                             </>
                         )
                     })}
                 </tbody>
             </table>
         </jnb.Row>
           <jnb.Modal show={showModelPopUp} onHide={handleClose} className="modal-md" backdrop="static">
           <jnb.Modal.Header className="bg-success bg-opacity-75 text-white" closeButton>
               <jnb.Modal.Title>Interview Status Details of {getUpdateInterviewStatus?.candidate_name}</jnb.Modal.Title>
           </jnb.Modal.Header>
           <jnb.Modal.Body>
           <jnb.Row className="px-3 pt-2">
           
           <table className="table table-condensed table-bordered table-striped table-responsive"
                            style={{ border: '1px solid lightblue', borderCollapse: 'collapse' }} >
                        <thead> 
                            <tr>
                                <th>S.No</th>
                                <th>Category</th>
                                <th>Score</th>
                            </tr>
                        </thead>
                        <tbody style={{ textAlign: "center" }}>
                            {getUpdateInterviewStatus && getUpdateInterviewStatus !== undefined && getUpdateInterviewStatus?.interviewcatdetails?.map((data, i) => {
                                return (<React.Fragment key={i} >
                                    <tr>
                                    <td>{i+1}</td>
                                    <td>{data?.category}</td>
                                    <td>{data?.score}</td>
                                    </tr>
                                </React.Fragment>
                                )
                            })}
                        </tbody>
                    </table>
           </jnb.Row>

</jnb.Modal.Body>
</jnb.Modal></>
        }
    </>)
}
