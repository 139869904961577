import '../jnanabhumi.css';
import '../Components/HRMS/LoginPage/hrms.css';
import * as jnb from "react-bootstrap";
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { AiFillHome, AiOutlineLogout } from 'react-icons/ai';
import Container from 'react-bootstrap/Container';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
export const logout = (navigate1) => {
  localStorage.clear();
navigate1("/")
}
const Header = () => {
  const logindetails = useSelector((state) => state.reducers?.loginreducer?.userLoginDetials);
  const navigate=useNavigate();

  return (

    <><Navbar collapseOnSelect expand="lg" bg="white" sticky="top" variant="light">
      <Container fluid>
        <Navbar.Brand href="#" style={{ color: 'green', fontWeight: 'bold' }}><img src="../img/hrms-logo.svg " width="200px" alt="Jnanabhumi" /></Navbar.Brand>
        {/* <Navbar.Brand style={{ color: 'green', fontWeight: 'bold' }}>HRMS</Navbar.Brand> */}
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            {/* <jnb.Nav.Link href="#"onClick={() => { navigate("/hrmsloginpage") }}>Home</jnb.Nav.Link> */}
            
            {/* <jnb.Nav.Link href="/">GOs</jnb.Nav.Link>
            <NavDropdown title="Dropdown" id="collasible-nav-dropdown">
              <NavDropdown.Item href="/">List 1 </NavDropdown.Item>
              <NavDropdown.Item href="/">List 2 </NavDropdown.Item>
              <NavDropdown.Item href="/">List 3 </NavDropdown.Item>
            </NavDropdown> */}
          </Nav>
          <Nav>
            <jnb.Nav.Link href="#"onClick={() => { navigate("/homepage") }}><AiFillHome /></jnb.Nav.Link>
            <NavDropdown title={<> <strong><span>{"Hi! "}</span>&nbsp;{logindetails?.employee_name}</strong> ( {logindetails?.username}-{logindetails?.designation})</>}
              id="collasible-nav-dropdown"  >
              {/* <NavDropdown.Item href="/">Change Password</NavDropdown.Item> */}
              <NavDropdown.Item onClick={()=>{logout(navigate)}}><AiOutlineLogout  
              // onClick={logout} 
              /> Signout </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar> </>
  );
}
export default Header;
