import { FormikProvider, useFormik } from "formik";
import { Form } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import * as jnb from "react-bootstrap";
import { useEffect, useState } from "react";
import { BsEye } from "react-icons/bs";
import { config } from "../../../../CommonUtils/CommonApis";
import { CommonAxiosGet } from "../../../../CommonUtils/CommonAxios";
import { viewBucketImage } from "../../../../CommonUtils/viewBucketImage";

export default function AppliedCandidateDetailsReport() {
    const technology_applied = localStorage.getItem("technology_applied");
    const designation_applied = localStorage.getItem("designation_applied")
    const [GetCandidateDetails, setCandidateDetails] = useState([])
    const navigate = useNavigate();
    const formIk = useFormik({
        enableReinitialize: true,
        initialValues: {},
        onSubmit: (values) => {
            console.log(null)
        },
    });
    useEffect(() => {
        let Url = config.url.COMMON_URL + "jobapplydtls/" + technology_applied + "/" + designation_applied;
        CommonAxiosGet(Url).then((res) => {
            if (res.data.status === true) {
                setCandidateDetails(res.data.applydata)
            }
            else {
                setCandidateDetails([])
            }
        })
    }, []);
    function NavigateToJobPosReport() {
        navigate("/jobpostreport")
    }
    function NavigateToupdatestatus(data) {
        navigate("/updatestatus");
        localStorage.setItem("candidatedetails", JSON.stringify(data));
    }
    return (<>
        <jnb.Row className="pt-3">
            <jnb.Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <Link to="/homepage">Home</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">Candidate Details</li>
                </ol>
            </jnb.Col>
            <jnb.Col xs={12} sm={12} md={12} lg={5} xl={5} xxl={5}>
                <button type="button" class="btn btn-secondary btn-sm float-end" onClick={() => { NavigateToJobPosReport() }}>Back</button>
            </jnb.Col>
        </jnb.Row>
        <jnb.Row className="pt-3">
            <jnb.Row className="px-3 pt-3">
                <jnb.Col xs={12} sm={12} md={12} lg={11} xl={11} xxl={11}>
                    <div className="head-text" style={{ display: "inline" }}>
                        <h1>Candidate Details </h1> </div></jnb.Col>
            </jnb.Row>
            <FormikProvider value={formIk}>
                <Form onSubmit={formIk.handleSubmit} onChange={formIk.handleChange}>
                    <jnb.Row className="px-3 pt-0">
                        <table  className="table table-condensed table-bordered table-striped table-responsive"
                    style={{ border: '1px solid lightblue', width: '90%', borderCollapse: 'collapse' }}>
                            <thead>
                                <tr>
                                    <th align="center">S.No</th>
                                    <th>Name</th>
                                    <th>Years of Experience</th>
                                    <th>Present Designation</th>
                                    <th>Presnet Company</th>
                                    <th>Mobile</th>
                                    <th>Email</th>
                                    <th>Remarks</th>
                                    <th>Resume</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody style={{ textAlign: "left" }}>
                                {GetCandidateDetails !== undefined && GetCandidateDetails.map((data, i) => {
                                    return (
                                        <>
                                            <tr key={i}>
                                                <td width="100px" align="center">{i + 1}</td>
                                                <td>{data.candidate_name}</td>
                                                <td>{data.years_of_experience}</td>
                                                <td>{data.presnet_designation}</td>
                                                <td>{data.presnet_company}</td>
                                                <td>{data.mobile}</td>
                                                <td>{data.email}</td>
                                                <td>{data.remarks}</td>
                                                <td align="center">
                                                    <button type="button" class="btn btn-primary btn-sm rounded" onClick={() => {
                                                        let img = data.resume_upload
                                                        viewBucketImage(img)
                                                    }} ><BsEye /> </button>
                                                </td>
                                                <td>
                                                    <h5 style={{ color: "blue", fontSize: '1.5vh', textAlign: "center",cursor:"pointer",textDecoration:"underline" }}
                                                        onClick={() => { NavigateToupdatestatus(data) }}>update</h5>
                                                </td>
                                            </tr>
                                        </>
                                    )
                                })}
                            </tbody>
                        </table>
                    </jnb.Row>
                  
                </Form>
            </FormikProvider>
        </jnb.Row>
    </>)
}