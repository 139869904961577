import { Field, Form, FormikProvider, useFormik } from 'formik';
import React, { useEffect, useRef, useState } from 'react'
import * as jnb from "react-bootstrap";
import { useSelector } from 'react-redux';
import { useReactToPrint } from 'react-to-print';
import { CommonAxiosGet } from '../../../CommonUtils/CommonAxios';
import { config } from '../../../CommonUtils/CommonApis';
import { InfoAlert } from '../../../CommonUtils/SweetAlerts';
import SearchAndExportSection from '../CommonPages/SearchAndExportSection';
import CommonHomePaheName from '../../../CommonUtils/CommonHomePaheName';

export default function DailyWorkStatusReportRO() {
    const logindetails = useSelector((state) => state.reducers?.loginreducer?.userLoginDetials);

    const [showWorkStatusDetails, setWorkStatusDetails] = useState([]);
    const [showWorkStatusErrMsg, setWorkStatusErrMsg] = useState(false);
    const [showWorkStatusTable, setWorkStatusTable] = useState(false);
    const [WorkStatussearchQuery1, setWorkStatusSearchQuery1] = useState('');
    const [showRoMstDetails, setRoMstDetails] = useState([])
    const WorkStatusformIk = useFormik({
        enableReinitialize: true,
        initialValues: {
            frs_from_date: "",
            frs_to_date: "",
            ro_mst: "",
        },
        onSubmit: (values) => { console.log(null) },
    });

    function GetworkStatusProjectDetails(att_from_date, att_out_date, ro_mst) {
        const roValue=ro_mst.split("@@")[0]
        const HROrRo= (logindetails?.username === "hradmin" || logindetails?.roles?.includes("ADMIN") )? roValue : logindetails?.username
        let Url = (config.url.COMMON_URL + "dailyworklogRonew/" + att_from_date + "/" + att_out_date + "/" + HROrRo);
        CommonAxiosGet(Url).then((resForworkstatus) => {
            if (resForworkstatus?.data?.status === true) {
                setWorkStatusDetails(resForworkstatus.data?.dailyworklogRonew)
                setWorkStatusErrMsg(false);
                setWorkStatusTable(true)
            }
            else {
                setWorkStatusDetails([])
                setWorkStatusErrMsg(true)
                setWorkStatusTable(true)
            }
        })
    }

    function GetWorkStatusToDate(getworkdate) {
        const getfromDate = new Date(getworkdate);

        const gettoDateMin = getfromDate.toISOString().split("T")[0];

        const gettoDateMax = new Date(getfromDate);
        gettoDateMax.setDate(getfromDate.getDate() + 6); // Add 7 days to the "From Date"
        const gettoDateMaxFormatted = gettoDateMax.toISOString().split("T")[0];

        const toDateInputin = document?.getElementsByName("frs_to_date")[0];

        if (toDateInputin) {
            toDateInputin.min = gettoDateMin;  // Set "min" to the "From Date"
            toDateInputin.max = gettoDateMaxFormatted;  // Set "max" to 7 days after the "From Date"
        }
    }

   
    let workstatuscomponentRef = useRef();
    const frshandleprint = useReactToPrint({
        content: () => workstatuscomponentRef.current,
        documentTitle: "Daily Work Status Report",
    });
    function GetRoDetails() {
        let Url = config.url.COMMON_URL + "getronames"
        CommonAxiosGet(Url).then((resforRo) => {
            if (resforRo?.data?.status === true) {
                setRoMstDetails(resforRo?.data?.getronames);
            }
            else {
                setRoMstDetails([]);
            }
        })
    }
    useEffect(() => {
        if (logindetails?.username === "hradmin" || logindetails?.roles?.includes("ADMIN")) {
            GetRoDetails()
        }
    }, [])
    return (
        <div>
            <jnb.Row className="m-0">
                <jnb.Col xs={12} sm={12} md={12} lg={11} xl={11} xxl={11}>
                    <CommonHomePaheName homeLink="/homepage" currentPage="Daily Work Status Report" />
                </jnb.Col>
            </jnb.Row>

            <jnb.Row className=" pt-2">
                <FormikProvider value={WorkStatusformIk}>
                    <Form onSubmit={WorkStatusformIk?.handleSubmit} onChange={WorkStatusformIk?.handleChange} autoComplete="off"  >
                        <div className="border px-3 pb-3 mb-4 pt-1">
                            <jnb.Row className="px-3">
                                <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                                    <jnb.InputGroup className="mb-1">
                                        <label className="w-100 mb-2"><b>From Date </b></label>
                                        <Field type="date" name="frs_from_date"
                                            className="form-control" max={new Date().toISOString().split("T")[0]}
                                            onChange={(e) => {
                                                WorkStatusformIk?.setFieldValue("frs_to_date", "");
                                                WorkStatusformIk?.setFieldValue("ro_mst", ""); GetWorkStatusToDate(e?.target?.value); setWorkStatusTable(false);
                                                setWorkStatusSearchQuery1(''); 
                                                setWorkStatusDetails([]);
                                            }} />
                                    </jnb.InputGroup>
                                </jnb.Col>
                                <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                                    <jnb.InputGroup className="mb-1">
                                        <label className="w-100 mb-2"><b>To Date </b></label>
                                        <Field type="date" name="frs_to_date" className="form-control" onChange={(e) => {
                                            setWorkStatusTable(false); setWorkStatusDetails([]);
                                            setWorkStatusSearchQuery1(''); 
                                            WorkStatusformIk?.setFieldValue("ro_mst", "");
                                        }} max={new Date().toISOString().split("T")[0]} />
                                    </jnb.InputGroup>
                                </jnb.Col>
                                {(logindetails?.username === "hradmin" || logindetails?.roles?.includes("ADMIN")) && (
                                    <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                                        <jnb.InputGroup className="mb-1">
                                            <label className="w-100 mb-2"><b>RO Name</b></label>
                                            <Field as="select" className="form-control mb-2" name="ro_mst"
                                                onChange={(e) => {
                                                    setWorkStatusTable(false); setWorkStatusDetails([]);
                                                    setWorkStatusSearchQuery1(''); 
                                                }}>
                                                <option value="">--Search here--</option>
                                                {showRoMstDetails?.map((unitdata, i) => (
                                                    <option key={i} value={unitdata?.cfms_id+"@@"+unitdata?.emp_name}>{unitdata?.emp_name}</option>))}
                                            </Field>
                                        </jnb.InputGroup>
                                    </jnb.Col>
                                )}
                                <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                                    <button type="button" className='btn btn-success mt-4' onClick={() => {
                                        const fromDateInwork = new Date(WorkStatusformIk?.values?.frs_from_date);
                                        const toDateInwork = new Date(WorkStatusformIk?.values?.frs_to_date);
                                        if (!WorkStatusformIk?.values?.frs_from_date || !WorkStatusformIk?.values?.frs_to_date) {
                                            InfoAlert("From Date and To Date are required", 'warning');
                                            return;
                                        }
                                        if ((logindetails?.username === "hradmin" || logindetails?.roles?.includes("ADMIN")) && !WorkStatusformIk?.values?.ro_mst) {
                                            InfoAlert("RO Name is required", 'warning');
                                            return;
                                        }
                                        if (toDateInwork < fromDateInwork) {
                                            InfoAlert("To Date cannot be earlier than From Date", 'warning');
                                        } else {
                                            const frsdiffTime = (toDateInwork - fromDateInwork);
                                            const frsdiffDays = (frsdiffTime / (1000 * 60 * 60 * 24)) + 1;

                                            if (frsdiffDays > 7) {
                                                InfoAlert("The date range should not exceed 7 days", 'warning');
                                            } else {
                                                GetworkStatusProjectDetails(WorkStatusformIk?.values?.frs_from_date, WorkStatusformIk?.values?.frs_to_date, WorkStatusformIk?.values?.ro_mst);
                                            }
                                        }
                                    }}>Get Details</button>
                                </jnb.Col>
                            </jnb.Row>
                        </div>
                        {showWorkStatusTable === true && showWorkStatusDetails?.length > 0 && showWorkStatusDetails != "No data Found" ?
                            <SearchAndExportSection
                                searchValue={WorkStatussearchQuery1}
                                onSearchChange={(e) => setWorkStatusSearchQuery1(e.target.value)}
                                placeholder="Search by ID or Name or Unit"
                                handlePrint={frshandleprint}
                                filename="Daily Work Status Report"
                            /> : null}
                        <div className="table-container">
                            {showWorkStatusTable === true ? (<>
                                {showWorkStatusDetails?.length > 0 && showWorkStatusDetails != "No data Found" ? (
                                    <div
                                        style={{ maxHeight: '525px', overflowY: "auto", overflowX: "auto", paddingBottom: "20px" }}>
                                        
                                        <table className="sticky-table" ref={workstatuscomponentRef} id="table-to-xls">
                                            <thead className="sticky-header">
                                                <tr>
                                                    <th colSpan={4} style={{textAlign:"center"}}>
                                                        {(logindetails?.username === "hradmin" || logindetails?.roles?.includes("ADMIN"))? WorkStatusformIk?.values?.ro_mst?.split("@@")[1] : logindetails?.employee_name}
                                                        </th>
                                                </tr>
                                                <tr>
                                                    <th className="sticky-column sticky-header" width="2%">S.No</th>
                                                    <th className="sticky-column sticky-header" width="15%" style={{textAlign:"center"}}>Name</th>
                                                    <th className="sticky-column sticky-header" width="15%" style={{textAlign:"center"}}>Projects</th>
                                                    <th className="sticky-header" style={{textAlign:"center"}}>Date & Work Done</th>
                                                </tr>
                                            </thead>
                                            <tbody style={{ textAlign: "left" }}>
                                                {showWorkStatusDetails && showWorkStatusDetails?.filter((elementAtstatus) =>
                                                    elementAtstatus?.emp_name?.toLowerCase().includes(WorkStatussearchQuery1?.toLowerCase()) ||
                                                    elementAtstatus?.desig?.toLowerCase().includes(WorkStatussearchQuery1?.toLowerCase()) ||
                                                    elementAtstatus?.project_name?.toString().toLowerCase().includes(WorkStatussearchQuery1?.toLowerCase())
                                                )?.map((dataAtworkstats, i) => {
                                                    let jsonString = [];
                                                    if (dataAtworkstats?.date_wise) {
                                                        try {
                                                            jsonString = JSON.parse(dataAtworkstats?.date_wise);
                                                        } catch (error) {
                                                            console.error("Invalid JSON format in date_wise", error);
                                                        }
                                                    }

                                                    return (
                                                        <tr key={i}>
                                                            <td >{i + 1}</td>
                                                            <td>
                {dataAtworkstats?.emp_name}
                <span className="emp-designation">({dataAtworkstats?.desig})</span>
            </td>
                                                            <td> {dataAtworkstats?.project_name
                                                                ? dataAtworkstats.project_name.split(',').map((projectsname, index) => (
                                                                    
                                                                      <li key={index} style={{listStyleType:"none"}}>{projectsname}</li>
                                                                    // <>{projectsname}<br/></>
                                                                ))
                                                                : null
                                                            }</td>
                                                            <td>
                                                                {jsonString?.length > 0 && jsonString?.some(entry => entry !== null && entry !== undefined) ? (
                                                                    jsonString.map((workstatusentry, index) => (
                                                                        workstatusentry != null && workstatusentry !== undefined ? (
                                                                            <>
                                                                            <li key={index} className="emp-name">{workstatusentry}</li>
                                                                            {/* {workstatusentry}<br/> */}
                                                                            </>
                                                                        ) : null
                                                                    ))
                                                                ) : (
                                                                    "-"
                                                                )}
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                ) : (showWorkStatusErrMsg === true ? (<center><b style={{ color: "red" }}>*******No Data Found******* </b></center>) : (""))}
                            </>) : ("")}
                        </div>
                    </Form>
                </FormikProvider>
            </jnb.Row>
        </div>
    )
}
