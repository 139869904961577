import axios from "axios";
import Sweetalert from "./SweetAlerts";
import { store } from "../store";
import { IMG_DOWNLOAD_URL } from "./CommonApis";
export const CommonAxiosGet = async (url, values) => {
  var token = JSON.parse("\"" + localStorage.getItem("token") + "\"");
  try {
    let data = ''
    let res = await axios({
      url: url,
      method: 'get',
      data: values,
      headers: { Authorization: "Bearer " + token }
    })
    if (res.status === 200) {
      data = res
    }
    return data
  }
  catch (err) { catchErrorMessages(err) }
};
export const CommonAxiosPost = async (url, values) => {
  try {
    let data = "";
    var token = JSON.parse("\"" + localStorage.getItem("token") + "\"");
    let res = await axios({
      url: url,
      method: "post",
      data: values,
      headers: { Authorization: "Bearer " + token },
    });
    if (res.status === 200) {
      data = res;
    }
    return data;
  } catch (err) { catchErrorMessages(err) }
};
export const CommonAjaxPostWithFileUpload = async (url, values) => {
  let res = '';
  try {
    res = await axios({
      url: url,
      method: 'post',
      async: false,
      contentType: false,
      processData: false,
      data: values,
    })
    if (res.status === 200) { console.log(null) }
    return res
  }
  catch (err) { catchErrorMessages(err) }
};
export const CommonAxiosPostLogin = async (url, values) => {
  let data = "";
  try {
    let res = await axios({
      url: url,
      method: "post",
      data: values,
    });
    if (res.status === 200) {
      data = res;
    }
    return data;
  } 
  catch (err) { catchErrorMessages(err) }
};

export const CommonAxiosDelete = async (url, values) => {
  var token = JSON.parse("\"" + localStorage.getItem("token") + "\"");
  try {
    let data = ''
    let res = await axios({
      url: url,
      method: 'delete',
      data: values,
      headers: { Authorization: "Bearer " + token }
    })
    if (res.status === 200) {
      data = res
    }
    return data
  }
  catch (err) { catchErrorMessages(err) }
};

const catchErrorMessages = (err) => {

  const { dispatch } = store;
  if (err.response.status === 401) {
    Sweetalert("UnAuthorised Access!.", "error").then(function () {
      window.location.href = "/hrmsloginpage";
    })
    setTimeout(() => { dispatch({ type: 'HIDE_SPINNER' }); }, 25000);
  }

  else if (err.response.status === 404) {
    Sweetalert("Data Not Found!", 'error').then(function (isConfirm) {
      if (isConfirm.value) { console.log(null) }
    })

  } else if (err.response.status === 500) {
    Sweetalert("Could Not Fetch Data!", 'error').then(function (isConfirm) {
      if (isConfirm.value) { console.log(null) }
    })

  }
  else {
    if (err?.response?.data?.errorMessage !== null && err?.response?.data?.errorMessage !== undefined && err?.response?.data?.errorMessage?.length > 0) {
      let errorMessage = err?.response?.data?.errorMessage;
      Sweetalert(errorMessage, 'error')
    }
    else {
      Sweetalert("Internal Server Issue, Please Try Again", 'error')
    }
  }
  console.log("err",err)
  setTimeout(() => { dispatch({ type: 'HIDE_SPINNER' }); }, 25000);
  return ''
}

export const CommonAxiosPut = async (url, values) => {
  try {
    let data = "";
    var token = JSON.parse("\"" + localStorage.getItem("token") + "\"");
    let res = await axios({
      url: url,
      method: "put",
      data: values,
      headers: { Authorization: "Bearer " + token },
    });
    if (res.status === 200) {
      data = res;
    }
    return data;
  } catch (err) { catchErrorMessages(err) }
};

export const downloadUpdatedStagephotoOrFile = (fileName) => {

  axios.get(`${IMG_DOWNLOAD_URL}${fileName}`, { responseType: "blob" })
    .then((res) => {
      if (res.data) {
        const fileType = getFileType(fileName);
        const blobType = fileType.startsWith('image') ? fileType : 'application/pdf';

        const blob = new Blob([res.data], { type: blobType });

        const url = window.URL.createObjectURL(blob);
        let tab = window.open();
        tab.location.href = url;
      } else { console.log(null) }
    })
    .catch((error) => {
      console.error("Error downloading file:", error);
    });
};

const getFileType = (fileName) => {
  const extension = fileName.split('.').pop().toLowerCase();
  switch (extension) {
    case 'jpg':
    case 'jpeg':
      return 'image/jpeg';
    case 'png':
      return 'image/png';
    case 'pdf':
      return 'application/pdf';
    default:
      return 'application/octet-stream';
  }
};
