import { FormikProvider, useFormik, Form} from "formik";
import { Link } from "react-router-dom";
import * as jnb from "react-bootstrap";
import React, { useEffect, useRef, useState } from "react";
import { config } from "../../../CommonUtils/CommonApis";
import { CommonAxiosGet } from "../../../CommonUtils/CommonAxios";
import { SiMicrosoftexcel } from "react-icons/si";
import ReactHtmlTableToExcel from 'react-html-table-to-excel';
import SearchAndExportSection from "../CommonPages/SearchAndExportSection";
import { useReactToPrint } from "react-to-print";
export default function TotalLeaveDetailsReport(){

    const [search, setSearch] = useState("");
    const [GetDetails, setDetails] = useState([])
    const [currentPage, setCurrentPage] = useState(1);
    const [frssearchQuery, setfrsSearchQuery] = useState('');
  const [rowsPerPage] = useState(15); 
    const formIk = useFormik({
        enableReinitialize: true,
        initialValues: {},
        onSubmit: (values) => {console.log("submit values", values)},
    });


function GetData() {
    let Url = (config.url.COMMON_URL + "leavesdatHR");
    CommonAxiosGet(Url).then((res) => {
        setDetails(res.data.leavesdataHR)
    })
}
const fetchData = useRef(false)
useEffect(() => {
    if (!fetchData.current) {
        GetData()
        fetchData.current = true
    }
}, []);
const totalRows = GetDetails?.length;
  const totalPages = Math.ceil(totalRows / rowsPerPage);

  const startIndex = (currentPage - 1) * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;

  const displayedRows = GetDetails?.filter((element) =>
      element?.emp_name?.toLowerCase().includes(search.toLowerCase()) ||
      element?.emp_id?.toString().toLowerCase().includes(search.toLowerCase())
    )
    .slice(startIndex, endIndex);
    let frscomponentRef = useRef();
    const frshandleprint = useReactToPrint({
        content: () => frscomponentRef.current,
        documentTitle: "Leaves Report",
    });
    return (<>
        <jnb.Row className=" m-0">
            <jnb.Col xs={12} sm={12} md={12} lg={11} xl={11} xxl={11}>
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/homepage">Home</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">Report of Leaves</li>
                </ol>
            </jnb.Col>
            <SearchAndExportSection
                                    searchValue={frssearchQuery}
                                    onSearchChange={(e) => setfrsSearchQuery(e.target.value)}
                                    placeholder="Search here ID or Name or Unit"
                                    handlePrint={frshandleprint}
                                     filename="Leaves Report"
                                />
            {/* <jnb.Col xs={12} sm={12} md={12} lg={1} xl={1} xxl={1} className='excel-print'>
                    <ReactHtmlTableToExcel id="test-table-xls-button"
                        className="pull-right btn btn-sm btn-success" table="table-to-xls"
                        filename="Report of Leaves" sheet="tablexls"
                        buttonText={<>
                            <SiMicrosoftexcel size={20} title="Excel" /> Excel
                        </>} />
                    &nbsp;
                </jnb.Col> */}
        </jnb.Row>

        <jnb.Row className=" pt-2">
            <FormikProvider value={formIk}>
                <Form onSubmit={formIk.handleSubmit} onChange={formIk.handleChange} autoComplete="off"  >
                    <jnb.Row className="px-3">
                    <div style={{ maxHeight: '778px', overflowY: 'auto' }}>
                         <table className="sticky-table" id="table-to-xls" ref={frscomponentRef}>
                            <thead className="sticky-header">
                                <tr>
                                    <th rowSpan={2} className="sticky-header">S.No</th>
                                    <th rowSpan={2} className="sticky-header">ID</th>
                                    <th rowSpan={2} className="sticky-header">Name</th>
                                    <th colSpan={3} className="sticky-header">Sick Leave</th>
                                    <th colSpan={3} className="sticky-header">Casual Leave </th>
                                    <th colSpan={3} className="sticky-header">Earned Leave </th>
                                    <th colSpan={1} className="sticky-header">Loss of Pay</th>
                                    <th colSpan={3} className="sticky-header">Total</th>
                                </tr>
                                <tr>
                                <th>Total </th>
                                <th> Used</th>
                                <th> Available</th>
                                <th>Total </th>
                                <th> Used</th>
                                <th> Available</th>
                                <th>Total </th>
                                <th> Used</th>
                                <th> Available</th>
                                <th> Used </th>
                                <th>Total</th>
                                <th>Used</th>
                                <th>Available</th>
                                </tr>
                            </thead>
                            <tbody style={{ textAlign: "left" }}>
                                     {GetDetails && GetDetails?.filter((element) =>
                                            element?.emp_name.toLowerCase().includes(frssearchQuery.toLowerCase()) ||
                                            element?.emp_id.toString().toLowerCase().includes(frssearchQuery.toLowerCase()))?.map((data, i) => (
                                        <tr key={i}>
                                            <td>{i + 1}</td>
                                            <td>{data?.emp_id}</td>
                                            <td>{data?.emp_name}</td>
                                            <td>{data?.sl_issued === null ? (<>0</>):(<>{data?.sl_open}</>)}</td>
                                            <td>{data?.sl_used === null ? (<>0</>):(<>{data?.sl_avail}</>)}</td>
                                            <td>{data?.sl_available === null ? (<>0</>):(<>{data?.sl_bal}</>)}</td>
                                            <td>{data?.cl_issued === null ? (<>0</>):(<>{data?.cl_open}</>)}</td>
                                            <td>{data?.cl_used === null ? (<>0</>):(<>{data?.cl_avail}</>)}</td>
                                            <td>{data?.cl_available === null ? (<>0</>):(<>{data?.cl_bal}</>)}</td>
                                            <td>{data?.er_issued === null ? (<>0</>):(<>{data?.el_bal}</>)}</td>
                                            <td>{data?.er_used === null ? (<>0</>):(<>{data?.el_avail}</>)}</td>
                                            <td>{data?.er_available === null ? (<>0</>):(<>{data?.el_bf}</>)}</td>
                                            <td>{data?.loss_ofpay === null ? (<>0</>):(<>{data?.lop}</>)}</td>
                                            <td>{data?.sl_open + data?.cl_open + data?.el_bal}</td>
                                            <td>{data?.sl_avail + data?.cl_avail +data?.el_avail+data?.lop}</td>
                                            <td>{data?.sl_bal+data?.cl_bal+data?.el_bf}</td>
                                        </tr>
                                         ))}
                            </tbody>
                            {/* <tfoot>
                              <tr>
                                <td colSpan={14}></td>
                                <td colSpan={2}>
                                  <div className="d-flex tablepagination-box">
                                    <button onClick={() => setCurrentPage(1)} disabled={currentPage === 1}><b style={{fontSize:"20px",color:"#5DADE2"}}>{'<<'}</b></button>
                                    <button onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 1}>
                                      <b style={{fontSize:"20px"}}>{'<'}</b>
                                    </button>
                                    <small><b>{`${currentPage}`}</b> of <b style={{fontSize:"9px"}}>{`${totalPages}`}</b> </small>
                                    <button onClick={() => setCurrentPage(currentPage + 1)} disabled={endIndex >= totalRows}>
                                            <b style={{fontSize:"20px"}}>{'>'}</b></button>
                                    <button onClick={() => setCurrentPage(totalPages)} disabled={currentPage === totalPages}>
                                        <b style={{fontSize:"20px",color:"#5DADE2"}}>{'>>'}</b>
                                    </button> 
                                  </div>
                                </td>
                              </tr>
                            </tfoot> */}
                        </table> 
                        </div>
                    </jnb.Row>
                </Form>
            </FormikProvider>
        </jnb.Row>
    </>)
}