import React, { useEffect, useRef, useState } from 'react';
import { useTable, usePagination, useSortBy, useFilters, useGlobalFilter, useAsyncDebounce } from "react-table";
import { SiMicrosoftexcel } from 'react-icons/si';
import { BsPrinter } from 'react-icons/bs';
import { useReactToPrint } from 'react-to-print';
import ReactHtmlTableToExcel from 'react-html-table-to-excel';
import { CgPushChevronLeft, CgPushChevronRight } from "react-icons/cg";
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import * as jnb from 'react-bootstrap';

export default function CommonReactTable(props) {

    const { columns, data, filename, showFooter, headerName, isPageLength } = props

    const {
        getTableBodyProps,
        headerGroups,
        footerGroups,
        page,
        canPreviousPage,
        canNextPage,
        preGlobalFilteredRows,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        state: { pageIndex, pageSize },
        setPageSize,
        globalFilter,
        setGlobalFilter,
        rows,
        prepareRow
    } = useTable({
        columns,
        data,

        initialState: { pageIndex: 0, pageSize: 10 },

    },

        useFilters,
        useGlobalFilter,
        useSortBy,
        usePagination,
    );

    const [value, setValue] = React.useState(globalFilter)
    const onChange = useAsyncDebounce(valuess => {
        setGlobalFilter(valuess)
    }, 200)


    const hasItemNameTelugu = data?.some(item => item?.item_name_telugu);
    const shouldHideSearch = !globalFilter && hasItemNameTelugu;

    let componentRef = useRef();
    const handleprint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: filename,
    });
    const [currentPage, setCurrentPage] = useState(1);
    function rowsCount(aforrowscount, rowsforcount, pageIndexforcount, pageSizeforcount) {
        return rowsforcount
            .slice(pageIndexforcount * pageSizeforcount, (pageIndexforcount + 1) * pageSizeforcount)
            .reduce((sum, row) => {
                const sumvalue = parseFloat(row?.values[aforrowscount.Footer]);
                return isNaN(sumvalue) ? sum : sum + sumvalue;
            }, 0);
    }
    useEffect(() => {
        setPageSize(10)
    }, [isPageLength])

    function getLowLevelColumnCount(columns1) {
        let count = 0;

        function countColumns(cols) {
            cols.forEach(col => {
                if (col.columns) {
                    countColumns(col.columns);
                } else {
                    count += 1;
                }
            });
        }
        countColumns(columns1);
        return count;
    }

    const lowLevelColumnCount = getLowLevelColumnCount(columns);
    return (
        <React.Fragment>
            <jnb.Row>
                <jnb.Col xs={12} sm={12} md={8} lg={8} xl={8} xxl={8} >
                    {!shouldHideSearch && (<>
                        <input
                            value={value || ""}
                            name={`search_${Date.now()}`}
                            onChange={e => {
                                setValue(e.target.value);
                                onChange(e.target.value);
                            }}
                            placeholder="Search"
                            style={{
                                fontSize: '1.1rem',
                                border: '1',
                            }}
                        />&nbsp;
                    </>)}
                </jnb.Col>
                <jnb.Col xs={12} sm={12} md={4} lg={4} xl={4} xxl={4} className=' excel-print  '>
                    <ReactHtmlTableToExcel id="test-table-xls-button"
                        className="pull-right btn btn-sm btn-success" table="table-to-xls"
                        filename={filename} sheet="tablexls"
                        buttonText={<>
                            <SiMicrosoftexcel size={20} title="Excel" /> Excel
                        </>} />
                    &nbsp;
                    <button type="button" className=" btn btn-primary btn-sm" onClick={handleprint}><BsPrinter title="Print" size={"20px"}
                    /> Print</button>
                </jnb.Col>

            </jnb.Row>
            <span><jnb.Col xs={12} className="float-end"></jnb.Col></span>


            <div className='table-responsive'>
                <table className="table table-condensed table-bordered table-striped table-responsive mb-0 mt-2"
                    style={{ border: '1px solid lightblue', width: '100%', borderCollapse: 'collapse', }}
                    ref={componentRef} id="table-to-xls" >
                    <thead >
                        {(headerName !== '' && headerName !== null && headerName !== undefined) && (<>
                            <tr style={{ top: "-2px" }}>
                                <th colSpan={lowLevelColumnCount} style={{ top: "-2px" }}> {headerName} </th>
                            </tr>
                        </>)}
                        {headerGroups.map((headerGroup, index) => (
                            <tr key={index} {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column, colindex) => {
                                    let sortingIndicator = '';
                                    if (column.isSorted) {
                                        sortingIndicator = column.isSortedDesc ? ' 🔽' : ' 🔼';
                                    }
                                    return (
                                        <th key={colindex} {...column.getHeaderProps(column.getSortByToggleProps())}>
                                            {column.render('Header')}
                                            <span>{sortingIndicator}</span>
                                        </th>
                                    );
                                })}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()} style={{ fontSize: "12px", whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        {page.map((row, rindex) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()} key={rindex}>
                                    {row.cells.map((cell, cellindex) => {
                                        return <td key={cellindex} {...cell.getCellProps()}>{cell.render("Cell")}</td>;
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                    {showFooter === "true" && (
                        <tfoot>
                            <tr>
                                {footerGroups[0].headers.map((column, cindex) => (
                                    <td
                                        key={cindex}
                                        {...column.getFooterProps()}
                                        style={{
                                            border: '1px solid black', textAlign: 'right',
                                            cursor: column.isClickable ? 'pointer' : 'auto',
                                            textDecoration: column.isClickable ? 'underline' : null
                                        }}
                                        onClick={() => {
                                            if (column.isClickable) {
                                                column.FooterFunc();
                                            }
                                        }}
                                    >
                                        {column.Footer === 'Total' ? column.Footer : rowsCount(column, rows, pageIndex, pageSize)}
                                    </td>
                                ))}
                            </tr>
                        </tfoot>
                    )}
                </table>
            </div>
            <div className="pagination1 mt-2">
                <button type='button' onClick={() => gotoPage(0)} disabled={!canPreviousPage}><CgPushChevronRight size={21} /></button>&emsp;
                <button type='button' onClick={() => previousPage()} disabled={!canPreviousPage}><FaChevronRight /></button>&emsp;
                <button type='button' onClick={() => nextPage()} disabled={!canNextPage}><FaChevronLeft /></button>&emsp;
                <button type='button' onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}><CgPushChevronLeft size={21} /></button>&emsp;
                <button type='button' className='mt-1'>&emsp;{currentPage} - <strong>{pageSize}</strong> of <strong>{preGlobalFilteredRows.length} </strong>  &emsp;</button>
                <select name="test" value={pageSize} onChange={e => { setPageSize(Number(e.target.value)); setCurrentPage(1); }} >
                    <option value={rows.length}>All</option>
                    <option value="10">10</option>
                    <option value="20">20</option>
                </select>
                Rows per page : &emsp;
            </div>
        </React.Fragment>
    );
}