import { Field, FormikProvider, useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import * as jnb from "react-bootstrap";
import { config } from '../../../../CommonUtils/CommonApis';
import { CommonAxiosGet, CommonAxiosPost } from '../../../../CommonUtils/CommonAxios';
import Sweetalert, { failureAlert, successAlert } from '../../../../CommonUtils/SweetAlerts';
import { SiMicrosoftexcel } from 'react-icons/si';
import ReactHtmlTableToExcel from 'react-html-table-to-excel';
export default function LeaveManagementMaster() {
    const formIk = useFormik({
        enableReinitialize: true,
        initialValues: {
            updatedValues: {}
        },
        onSubmit: (values) => {
            if (selectedEmployees?.length === 0) {
                Sweetalert("Please select at least one employee.", "error");
                return;
            }
            postdietdata()
        },
    });

    const [showLeavesData, setLeavesData] = useState([]);
    const [showDetailedLeavesData, setDetailedLeavesData] = useState([]);
    const [filteredData1, setFilteredData1] = useState([]);
    const [showCategoryName, setCategoryName] = useState("");
    const [selectedEmployees, setSelectedEmployees] = useState([]);
    const [updatedValues, setUpdatedValues] = useState({});

    const handleSelectEmployee = (e, empId) => {
        if (e.target.checked) {
            setSelectedEmployees((prevSelected) => [...prevSelected, empId]);
        } else {
            setSelectedEmployees((prevSelected) =>
                prevSelected.filter((id) => id !== empId)
            );
        }
    };

    const handleAvailableChange = (e, empId, leaveType) => {
        const { value } = e.target;

        setUpdatedValues((prevValues) => ({
            ...prevValues,
            [empId]: {
                ...prevValues[empId],
                [leaveType]: value,
            },
        }));
    };

    const [searchQueryAtDashboard, setSearchQueryAtDashboard] = useState('');

    const handleSearchChange = (e) => {
        const searchQuery = e.target.value;
        setSearchQueryAtDashboard(searchQuery);

        const filtered = showDetailedLeavesData?.filter((employee) =>
            employee.emp_id.toString().includes(searchQuery) ||
            employee.emp_name.toLowerCase().includes(searchQuery.toLowerCase())
        );

        setFilteredData1(filtered);
    };

    function GetLeavesData(categoryName) {
        CommonAxiosGet(config.url.COMMON_URL + "Leavesmaster")
            .then((response) => {
                if (response.data.LeavesmastercountStatus === true) {
                    setLeavesData(response.data.Leavesmastercount);
                } else if (response.data.LeavesmastercountStatus === false) {
                    setLeavesData([]);
                    Sweetalert(response.data.Leavesmastercountmessage, "warning");
                }
                if (response.data.LeavesmasterStatus === true) {


                    if (categoryName) {
                        const filtered = response.data.Leavesmaster.filter((item) => item.category_name === categoryName);
                        setFilteredData1(filtered);
                        setCategoryName(categoryName);
                        setDetailedLeavesData(filtered);
                    } else {
                        setFilteredData1([]);
                    }
                } else if (response.data.LeavesmasterStatus === false) {
                    setDetailedLeavesData([]);
                    Sweetalert(response.data.Leavesmastermessage, "warning");
                }
            })
            .catch((error) => {
                console.error("Error fetching leaves data:", error);
            });
    }

    useEffect(() => {
        GetLeavesData();
    }, []);

    const leaveCounts = {
        Regular: 0,
        Contract: 0,
        Intern: 0,
    };


    showLeavesData.forEach((leave) => {
        if (leave.category_name === "Full Time Employee") {
            leaveCounts.Regular = leave.count;
        } else if (leave.category_name === "Contract") {
            leaveCounts.Contract = leave.count;
        } else if (leave.category_name === "Intern") {
            leaveCounts.Intern = leave.count;
        }
    });

    const [secondStatus, setSecondStatus] = useState(false)
    function postdietdata(values) {
        const leavesmst = (filteredData1 || [])
            .filter((employee) => selectedEmployees.includes(employee.emp_id))
            .map((employee) => {
                const updatedLeaveData = updatedValues[employee.emp_id] || {};
                return {
                    emp_id: employee.emp_id,
                    sl_avail: parseFloat(updatedValues[employee.emp_id]?.sl_bal || employee.sl_bal),
                    cl_avail: parseFloat(updatedValues[employee.emp_id]?.cl_bal || employee.cl_bal),
                    el_avail: parseFloat(updatedValues[employee.emp_id]?.el_bal || employee.el_bal),
                    remarks : (updatedValues[employee.emp_id]?.remarks || employee.remarks),
                    insert_by: "hr"
                };
            });

        const payload = {
            leavesmst,
        };



        const url = config.url.COMMON_URL + "leavemasterupdate";
        CommonAxiosPost(url, payload)
            .then((res) => {
                if (res?.data?.ResponseCode === "01") {
                    successAlert(res?.data?.ResponseDesc);
                } else {
                    failureAlert(res?.data?.ResponseDesc);
                }
            })
            .catch((error) => {
                console.error("API Error:", error);
                failureAlert("An error occurred while submitting the data.");
            });
    }

    return (
        <FormikProvider value={formIk}>
            <jnb.Form onSubmit={formIk.handleSubmit} onChange={formIk.handleChange} onKeyDown={(e) => {
                if (e.key === "Enter") {
                    e.preventDefault(); // Prevent form submission on Enter
                }
            }}>
                <div className="border px-3 pt-4">

                    <div className="table-responsive">
                        <table className="table table-condensed table-bordered table-striped table-responsive">
                            <thead>
                                <tr>
                                    <th style={{ padding: "10px", width: "8%" }}>Sl.No</th>
                                    <th>Regular</th>
                                    <th>Contract</th>
                                    <th>Intern</th>
                                </tr>
                            </thead>
                            <tbody style={{ textAlign: "center" }}>
                                {Object.keys(leaveCounts).length > 0 ? (
                                    <tr>
                                        <td>1</td>
                                        <td
                                            onClick={() => {
                                                GetLeavesData("Full Time Employee");
                                                setSecondStatus(true)
                                                setSelectedEmployees([])
                                                setSearchQueryAtDashboard("")
                                            }}
                                            style={{ cursor: 'pointer', textDecoration: 'underline', color: 'blue', textAlign: 'center' }}>
                                            {leaveCounts.Regular}
                                        </td>
                                        <td
                                            onClick={() => {
                                                GetLeavesData("Contract"); setSecondStatus(true)
                                                setSelectedEmployees([])
                                                setSearchQueryAtDashboard("")
                                            }}
                                            style={{ cursor: 'pointer', textDecoration: 'underline', color: 'blue', textAlign: 'center' }}
                                        >
                                            {leaveCounts.Contract}
                                        </td>
                                        <td
                                            onClick={() => {
                                                GetLeavesData("Intern"); setSecondStatus(true)
                                                setSelectedEmployees([])
                                                setSearchQueryAtDashboard("")
                                            }}
                                            style={{ cursor: 'pointer', textDecoration: 'underline', color: 'blue', textAlign: 'center' }}
                                        >
                                            {leaveCounts.Intern}
                                        </td>
                                    </tr>
                                ) : (
                                    <tr>
                                        <td colSpan={4}>No data available</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>

                </div>
                {secondStatus ? (
                    <>
                        <jnb.Row>
                            <jnb.Col md={1}></jnb.Col>
                            <jnb.Col md={6} className='text-left'> <div className="head-text mt-4">
                                <h5>
                                    Employee Details ( <span style={{ color: "darkorange" }}>Category: &nbsp;{showCategoryName}</span> )
                                </h5>
                            </div></jnb.Col>
                            <jnb.Col md={2}>  <ReactHtmlTableToExcel id="test-table-xls-button"
                        className="pull-right btn btn-sm btn-success mt-4" table="table-to-xls"
                        filename={`Category: ${showCategoryName}`} sheet="tablexls"
                        buttonText={<>
                            <SiMicrosoftexcel size={20} title="Excel" /> Excel
                        </>} />
                    &nbsp;</jnb.Col>
                            <jnb.Col md={2} className='text-end mt-4 mb-2'>
                                <input
                                    className='form-control'
                                    type="text"
                                    placeholder="Search"
                                    value={searchQueryAtDashboard}
                                    onChange={handleSearchChange}
                                    name={`search_${Date.now()}`}
                                    id="search"
                                /></jnb.Col>
                            <jnb.Col md={1}></jnb.Col>
                        </jnb.Row>
                        <jnb.Row>
                            <jnb.Col md={1}></jnb.Col>
                            <jnb.Col md={10}>
                                <div className="table-responsive">
                                    <table className="table table-condensed table-bordered  table-responsive"  id="table-to-xls">
                                        <thead>
                                            <tr>
                                                <th rowSpan={2} style={{ width: "5%" }}>S.No</th>
                                                <th rowSpan={2} style={{ width: "5%" }}>ID</th>
                                                <th rowSpan={2} style={{ width: "20%" }}>Name</th>
                                                <th colSpan={3} style={{ width: "10%" }}>Sick Leave</th>
                                                <th colSpan={3} style={{ width: "10%" }}>Casual Leave</th>
                                                <th colSpan={3} style={{ width: "10%" }}>Earned Leave</th>
                                                <th rowSpan={2} style={{ width: "10%" }}>Remarks</th>
                                            </tr>
                                            <tr>
                                                <th style={{ width: "5px" }}>Total</th>
                                                <th style={{ width: "5px" }}>Used</th>
                                                <th style={{ width: "2px" }}>Available</th>
                                                <th style={{ width: "5px" }}>Total</th>
                                                <th style={{ width: "5px" }}>Used</th>
                                                <th style={{ width: "5px" }}>Available</th>
                                                <th style={{ width: "5px" }}>Total</th>
                                                <th style={{ width: "5px" }}>Used</th>
                                                <th style={{ width: "2px" }}>Available</th>
                                            </tr>
                                        </thead>
                                        <tbody style={{ textAlign: "left" }}>
                                            {!filteredData1?.length > 0 && (<tr>
                                                <td colSpan={12} className='text-center text-danger text-md'>*** No Records Found ***</td></tr>)}
                                            {filteredData1?.map((employee, i) => (
                                                <tr key={i}
                                                    style={{
                                                        backgroundColor: selectedEmployees.includes(employee.emp_id) ? '#D9F5FD' : 'transparent',
                                                    }}> <td>
                                                        {i + 1} &nbsp;&nbsp;&nbsp;
                                                        <Field
                                                            type="checkbox"
                                                            checked={selectedEmployees.includes(employee.emp_id)}
                                                            onChange={(e) => handleSelectEmployee(e, employee.emp_id)}
                                                        />
                                                    </td>
                                                    <td>{employee.emp_id}</td>
                                                    <td>{employee.emp_name}</td>
                                                    <td>{employee.sl_open}</td>
                                                    <td>{employee.sl_avail}</td>
                                                    <td>
                                                        {selectedEmployees.includes(employee.emp_id) ? (
                                                            <Field
                                                                type="text"
                                                                style={{
                                                                    width: '75%',
                                                                    textAlign: 'justify',
                                                                    border: '1px solid #ccc',
                                                                    borderRadius: '4px',
                                                                    padding: '4px',
                                                                }}
                                                                value={updatedValues[employee.emp_id]?.sl_bal ?? employee.sl_bal}
                                                                onChange={(e) => {
                                                                    const value = e.target.value;
                                                                    if (/^\d*\.?5?$/.test(value) || value === "") {
                                                                        handleAvailableChange(e, employee.emp_id, 'sl_bal');
                                                                    }
                                                                }}
                                                            />
                                                        ) : (
                                                            employee.sl_bal
                                                        )}
                                                    </td>

                                                    <td>{employee.cl_open}</td>
                                                    <td>{employee.cl_avail}</td>
                                                    <td>
                                                        {selectedEmployees.includes(employee.emp_id) ? (
                                                            <Field
                                                                type="text"
                                                                style={{
                                                                    width: '75%',
                                                                    textAlign: 'justify',
                                                                    border: '1px solid #ccc',
                                                                    borderRadius: '4px',
                                                                    padding: '4px',
                                                                }}
                                                                value={updatedValues[employee.emp_id]?.cl_bal ?? employee.cl_bal}
                                                                onChange={(e) => {
                                                                    const value = e.target.value;
                                                                    if (/^\d*\.?5?$/.test(value) || value === "") {
                                                                        handleAvailableChange(e, employee.emp_id, 'cl_bal');
                                                                    }
                                                                }}
                                                            />
                                                        ) : (
                                                            employee.cl_bal
                                                        )}
                                                    </td>
                                                    <td>{employee.el_bf}</td>
                                                    <td>{employee.el_avail}</td>
                                                    <td>
                                                        {selectedEmployees.includes(employee.emp_id) ? (

                                                            <Field
                                                                type="text"
                                                                style={{
                                                                    width: '75%',
                                                                    textAlign: 'justify',
                                                                    border: '1px solid #ccc',
                                                                    borderRadius: '4px',
                                                                    padding: '4px',
                                                                }}
                                                                value={updatedValues[employee.emp_id]?.el_bal ?? employee.el_bal}
                                                                onChange={(e) => {
                                                                    const value = e.target.value;
                                                                    if (/^\d*\.?5?$/.test(value) || value === "") {
                                                                        handleAvailableChange(e, employee.emp_id, 'el_bal');
                                                                    }
                                                                }}
                                                            />
                                                        ) : (
                                                            employee.el_bal
                                                        )}
                                                    </td>
                                                    <td>
                                                        {selectedEmployees.includes(employee.emp_id) ? (
                                                            <Field
                                                                type="text"
                                                                style={{
                                                                    width: '100%',
                                                                    textAlign: 'justify',
                                                                    border: '1px solid #ccc',
                                                                    borderRadius: '4px',
                                                                    padding: '4px',
                                                                }}
                                                                value={updatedValues[employee.emp_id]?.remarks ?? employee.remarks}
                                                                onChange={(e) => handleAvailableChange(e, employee.emp_id, 'remarks')}
                                                            />
                                                        ) : (
                                                            <center>{employee.remarks === null ? "-" :  employee.remarks}</center>
                                                        )}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                    <jnb.Row className="px-3 pt-1">
                                        <jnb.Col xs={12} sm={12} md={12} lg={12} xl={10} xxl={10}></jnb.Col>
                                        <jnb.Col xs={12} sm={12} md={12} lg={12} xl={2} xxl={2} style={{ float: "right" }}>
                                            <button type="submit" className="btn rounded-0 btn-success btn-sm mb-3 float-end" >Submit</button>
                                        </jnb.Col>
                                    </jnb.Row>
                                </div>
                            </jnb.Col>
                            <jnb.Col md={1}></jnb.Col>

                        </jnb.Row>
                    </>
                ) : null}


            </jnb.Form>
        </FormikProvider>
    );
}
