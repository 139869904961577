import { ErrorMessage, Field, Form, FormikProvider, useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import * as jnb from "react-bootstrap";
import * as Yup from 'yup';
import allowNumbersOnly, { AADHAR_VALIDATION, allowAlphabetAndSpacesOnly } from '../../../../CommonUtils/CommonValidations';
import Swal from 'sweetalert2';
import { CommonAxiosGet, CommonAxiosPost } from '../../../../CommonUtils/CommonAxios';
import { config } from '../../../../CommonUtils/CommonApis';
import Sweetalert from '../../../../CommonUtils/SweetAlerts';
import { ALPHABETS_ONLY, MOBILE_VALIDATION, STARTS_WITH_NO_WHITESPACE } from '../../../../CommonUtils/contextVariables';
import { useNavigate } from 'react-router-dom';
export default function RegistrationFormJopRequestes() {
  const navigate = useNavigate()
  const formIk = useFormik({
    initialValues: {
       aadhar:"",
      candidate_name:"",
      mobile:"",
      gender:"",
      dob:"",
      email:"",
      highest_qualification:"",
    },
    validationSchema:Yup.object().shape({
      // aadhar: Yup.string().required("Required").matches(AADHAR_VALIDATION, ' Invalid aadhar number').nullable(),
      candidate_name: Yup.string().required("Required").matches(ALPHABETS_ONLY, " Only alphabets are allowed")
        .matches(STARTS_WITH_NO_WHITESPACE, '* This field cannot contain only blankspaces').nullable(),
        mobile: Yup.string().required("Required").matches(MOBILE_VALIDATION, " Invalid mobile number").nullable(),
        gender:Yup.string().required("Required").nullable(),
        dob:Yup.string().required("Required").nullable(),
        email:Yup.string().required("Required").nullable(),
        highest_qualification:Yup.string().required("Required").nullable(),
    }),
    onSubmit: (values, { resetForm }) => sendOtp(values, resetForm),
  })
  const [showOtpBtn, setOtpBtn] = useState(true);
  const [resendOtp, setResendOtp] = useState(false);
  function sendOtp(values, resetForm) {
    Swal.fire({
        title: 'Are you Sure Want to Submit?',
        html: `Make Sure , OTP will be Send to <span style="color: blue;">${values?.mobile}</span>`, icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Send OTP",
    }).then((res) => {
        if (res.isConfirmed) {
            let otpUrl = config.url.COMMON_URL+ "employeeRegistration/generateotp?mobile=" + values?.mobile
            CommonAxiosGet(otpUrl).then((rres) => {
                if (rres?.data?.status === true) {
                    setOtpBtn(false);
                    setTimer(180);
                }
                else {
                    if (rres?.data?.scode === '03') {
                        Sweetalert(rres?.data?.sdesc, "warning")
                        resetForm()
                    } else {
                        Sweetalert(rres?.data?.sdesc, "warning")
                    }
                }
            })
        }
    })
}
const [timer, setTimer] = useState(180); 
useEffect(() => {
    let interval = null;
    if (!showOtpBtn) {
        interval = setInterval(() => {
            setTimer((prevTimer) => {
                if (prevTimer > 0) {
                    if (prevTimer < 122) {
                        setResendOtp(true);
                        return prevTimer - 1;
                    } else if (prevTimer > 1) {
                        return prevTimer - 1;
                    }
                    else {
                        clearInterval(interval);
                        setResendOtp(true); 
                        return 0;
                    }
                }
            });
        }, 1000);
    } else {
        clearInterval(interval);
    }
    return () => clearInterval(interval);
}, [showOtpBtn]);

const formatTime = (timeInSeconds) => {
    if (isNaN(timeInSeconds) || timeInSeconds < 0) {
        return "0:00";
    }
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = timeInSeconds % 60;
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
};
const resendOTP = () => {
  formIk.setFieldValue("otp", "")
    let values = formIk?.values
    sendOtp(values)
};
function submitValues() {
  let values = formIk?.values;
  let url =  config.url.COMMON_URL+ "savejobpostingreg"
  if (values?.otp) {
      CommonAxiosPost(url, values).then((res) => {
          if (res?.data?.ResponseCode === "01") {
              // Sweetalert(res.data?.ResponseDesc, 'success')
              Swal.fire({
                title: res?.data?.ResponseDesc,
                icon:"success",
                backdrop: false
            }).then((res) => {
                if (res?.isConfirmed === true) {
                  navigate("/hrmsloginpage")
                }
            });
          }
          else {
              Sweetalert(res.data?.ResponseDesc, 'warning')
          }
      })
  }
  else {
      Sweetalert('Please Enter OTP /  Valid OTP', 'error')
  }
}
  return (
    <>
    <div>
      <jnb.Container className="pb-5">
        <FormikProvider value={formIk}>
          <Form onSubmit={formIk.handleSubmit} onChange={formIk.handleChange}>
            <jnb.Row style={{marginTop:"80px"}}>
              <jnb.Col xs={12} sm={12} md={12} lg={1} xl={1} xxl={1}></jnb.Col>
              <jnb.Col xs={12} sm={12} md={12} lg={10} xl={10} xxl={10}>
                <div className="border-reg rounded p-3 shadow-sm mt-5" >
                  <jnb.Row className="pt-0">
                    <jnb.Col xs={12} sm={12} md={12} lg={10} xl={10} xxl={10}>
                      <div className="head-text  mt-4"> <h1>Registration Form </h1> </div></jnb.Col>
                  </jnb.Row>
                  <jnb.Row>
                    <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                      <jnb.InputGroup className="mb-3">
                        <label className="w-100 mb-2"><b>Full Name:<font style={{ color: "red" }}>*</font></b></label>
                        <Field type="text" name="candidate_name" className="form-control mb-2" onKeyPress={(e) => { allowAlphabetAndSpacesOnly(e); }}/>
                        <ErrorMessage name="candidate_name" component="div" className="text-error" />
                        </jnb.InputGroup>
                    </jnb.Col>
                    {/* <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                      <jnb.InputGroup className="mb-3">
                        <label className="w-100 mb-2"><b>Aadhar:<font style={{ color: "red" }}>*</font></b></label>
                        <Field type="text" name="aadhar" className="form-control mb-2" maxLength="12" onKeyPress={(e) => { allowNumbersOnly(e); }}/>
                        <ErrorMessage name="aadhar" component="div" className="text-error" />
                        </jnb.InputGroup>
                    </jnb.Col> */}
                    <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                      <jnb.InputGroup className="mb-3">
                        <label className="w-100 mb-2"><b>Gender:<font style={{ color: "red" }}>*</font></b></label>
                        <Field type="radio" name="gender" value="M" />&nbsp;Male&nbsp;
                        <Field type="radio" name="gender" value="F" />&nbsp;Female
                        <ErrorMessage name="gender" component="div" className="text-error" />
                      </jnb.InputGroup>
                    </jnb.Col>
                    <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                      <jnb.InputGroup className="mb-3">
                        <label className="w-100 mb-2"><b>DOB:<font style={{ color: "red" }}>*</font></b></label>
                        <Field type="date" name="dob" className="form-control mb-2" />
                        <ErrorMessage name="dob" component="div" className="text-error" />
                      </jnb.InputGroup>
                    </jnb.Col>
                  </jnb.Row>
                  <jnb.Row>
                    <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                      <jnb.InputGroup className="mb-3">
                        <label className="w-100 mb-2"><b>Mobile:<font style={{ color: "red" }}>*</font></b></label>
                        <Field type="text" name="mobile" className="form-control mb-2" maxLength="10" onKeyPress={(e) => { allowNumbersOnly(e); }}/>
                        <ErrorMessage name="mobile" component="div" className="text-error" />
                        </jnb.InputGroup>
                    </jnb.Col>
                    <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                      <jnb.InputGroup className="mb-3">
                        <label className="w-100 mb-2"><b>Email:<font style={{ color: "red" }}>*</font></b></label>
                        <Field type="text" name="email" className="form-control mb-2" maxLength="30"/>
                        <ErrorMessage name="email" component="div" className="text-error" />
                        </jnb.InputGroup>
                    </jnb.Col>
                    <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                      <jnb.InputGroup className="mb-3">
                        <label className="w-100 mb-2"><b>Highest Qualification:<font style={{ color: "red" }}>*</font></b></label>
                        <Field type="text" name="highest_qualification" className="form-control mb-2" maxLength="30"/>
                        <ErrorMessage name="highest_qualification" component="div" className="text-error" />
                        </jnb.InputGroup>
                    </jnb.Col>
                  </jnb.Row>
                  <jnb.Row className='pb-4'>
                                        <jnb.Col xs={12} sm={12} md={6} lg={3} xl={3} xxl={3}>
                                            {showOtpBtn === true ?
                                                <jnb.Button variant="secondary" type="submit" className="bg-dark submit-btn mt-4">
                                                    Generate OTP </jnb.Button>
                                                : showOtpBtn === false ?
                                                    <React.Fragment>
                                                        {resendOtp === false ?
                                                            <label className="input-lable mb-3 mt-4">OTP Sent to &nbsp;
                                                                <b className='text-primary'>{formIk?.values?.mobile} </b></label> :
                                                            resendOtp === true ? <React.Fragment>
                                                                <label className="input-lable mt-2">Didn't receive code? </label>
                                                                <jnb.Button variant="primary" type="button" className="submit-btn"
                                                                    onClick={() => { resendOTP() }}>Resend OTP </jnb.Button></React.Fragment>
                                                                : ''
                                                        }
                                                    </React.Fragment> : ''}
                                        </jnb.Col>
                                        {showOtpBtn === false ? <React.Fragment>
                                            <jnb.Col xs={12} sm={12} md={6} lg={3} xl={3} xxl={3} className='pt-2'>
                                                {showOtpBtn === false ? <React.Fragment> <p> Time Remaining:  {formatTime(timer)} </p> </React.Fragment> : '' }
                                            </jnb.Col>
                                            <jnb.Col xs={12} sm={12} md={6} lg={3} xl={3} xxl={3}>
                                                <jnb.InputGroup controlId="otp">
                                                    <Field type="text" name="otp" className="input-field form-control mt-4" placeholder="Enter OTP" maxLength={6} />
                                                </jnb.InputGroup>
                                            </jnb.Col>
                                            <jnb.Col xs={12} sm={12} md={6} lg={3} xl={3} xxl={3} className='pt-2' >
                                                <jnb.Button variant="success" type="button" className="submit-btn btn-success float-end mt-3" 
                                                onClick={() => { submitValues() }}>
                                                    Submit
                                                </jnb.Button>
                                            </jnb.Col>
                                        </React.Fragment> : ''}
                                    </jnb.Row>
                                  </div>
              </jnb.Col>
              <jnb.Col xs={12} sm={12} md={12} lg={1} xl={1} xxl={1}></jnb.Col>
            </jnb.Row>
          </Form>
        </FormikProvider>
      </jnb.Container>
    </div>
   
  </>
  )
}
