import { ErrorMessage, Field, FormikProvider, useFormik, Form } from "formik";
import { Link } from "react-router-dom";
import * as jnb from "react-bootstrap";
import { useEffect, useRef, useState } from "react";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import CommonPost from "../../../CommonUtils/CommonPost";
import Sweetalert from "../../../CommonUtils/SweetAlerts";
import { CommonAxiosGet } from "../../../CommonUtils/CommonAxios";
import { config } from "../../../CommonUtils/CommonApis";
import moment from 'moment';

export default function DailyworkStatus() {
    const [getprojectListData, setprojectListData] = useState([]);
    const [getWorkstatusprojectListData, setWorkstatusprojectListData] = useState([]);
    const [search, setSearch] = useState("");
    const [dateRange, setDateRange] = useState({ minDate: '', maxDate: '' });
    const logindetails = useSelector((state) => state.reducers?.loginreducer?.userLoginDetials);
    const validation = Yup.object().shape({
        work_date: Yup.string().required("Required").nullable(),
        project_id: Yup.string().required("Required").nullable(),
        remarks: Yup.string().required("Required").nullable(),
    });
    const jobpostingInitialValues = {
        emp_id: logindetails?.username,
        work_date: "",
        remarks: "",
        project_id: ""
    };

    const formIk = useFormik({
        enableReinitialize: true,
        initialValues: jobpostingInitialValues,
        onSubmit: (values) => {
            WorkstatusSave(values)
        },
        validationSchema: validation,
    });

    function GetProjectLists() {
        let Url = (config.url.COMMON_URL + "getprojects/" + logindetails?.username);
        CommonAxiosGet(Url).then((res) => {
            if (res.data.status === true) { setprojectListData(res.data.getprojects) }
            else { setprojectListData([]) }
        })
    }
    function GetworkStatusProjectDetails() {
        let Url = (config.url.COMMON_URL + "getdailyworkreport/" + logindetails?.username);
        CommonAxiosGet(Url).then((res) => {
            if (res.data.status === true) { setWorkstatusprojectListData(res.data.getdailyworkreport) }
            else { setWorkstatusprojectListData([]) }
        })
    }
    const jobPostingFetchData = useRef(false)
    useEffect(() => {
        if (!jobPostingFetchData.current) {
            GetProjectLists();
            GetworkStatusProjectDetails()
            jobPostingFetchData.current = true
        }
        // eslint-disable-next-line
    }, []);
    useEffect(() => {
        const today = new Date();
        const lastWeek = new Date(today);
        lastWeek.setDate(today.getDate() - 6);
        const todayFormatted = today.toISOString().split('T')[0];
        const lastWeekFormatted = lastWeek.toISOString().split('T')[0];
        setDateRange({
            minDate: lastWeekFormatted,
            maxDate: todayFormatted,
        });
    }, []);
    
    const WorkstatusSave = (values) => {
        Swal.fire({
            title: 'Are you sure you want to submit?',
            text: 'Please check it once before submitting',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Submit',
            cancelButtonText: 'Cancel',
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                CommonPost.saveDailuWotkStatus(values)
                    .then((res) => {
                        if (res.data.ResponseCode === "01") {
                            // Swal.fire('Successfully Submitted', 'Success', 'success',);
                            Swal.fire({
                                title: res?.data?.ResponseDesc,
                                icon:"success",
                                backdrop: false
                            }).then((resforDesc) => {
                                if (resforDesc?.isConfirmed === true) {
                                    GetworkStatusProjectDetails()
                                    formIk?.setFieldValue("work_date","");
                                    formIk?.setFieldValue("project_id","");
                                    formIk?.setFieldValue("remarks","");
                                }
                            })
                        }
                        else if (res.data.ResponseCode === "02") {
                            Sweetalert(res.data.ResponseDesc, 'warning');
                        }
                        else {
                            Swal.fire('Canceled', 'Something went wrong,Please check', 'error');
                        }
                    })
                    .catch(() => { console.log("Exception Occured 71 "); });
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire('Canceled', 'Do corrections and then retry :)', 'error');
            }
        })
    };
    const handleRemarksChange = (e) => {
        const value = e.target.value;
        const wordCount = value.trim().split(/\s+/).length;

        if (wordCount <= 20) {
            formIk.setFieldValue("remarks", value);  // Set the value if the word count is valid
        }
    };
    return (<>
        <jnb.Row className=" pt-2">
            <jnb.Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <Link to="/homepage">Home</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">Daily Work Status</li>
                </ol>
            </jnb.Col>

        </jnb.Row>
        <jnb.Row className=" pt-2">
            <FormikProvider value={formIk}>
                <Form onSubmit={formIk.handleSubmit} onChange={formIk.handleChange}>
                    <jnb.Row className=" pt-5 border m-0 mb-3">
                        <jnb.Col xs={12} sm={12} md={12} lg={2} xl={2} xxl={2}></jnb.Col>
                        <jnb.Col xs={12} sm={12} md={12} lg={2} xl={2} xxl={2}>
                            <jnb.InputGroup className="mb-4p5">
                                <span className="label-text-style"><b>Date:<font style={{ color: "red" }}>*</font></b></span>
                                <Field type="date" className="form-control mb-2" name="work_date"
                                    min={dateRange.minDate}
                                    max={dateRange.maxDate} />
                                <ErrorMessage name="work_date" component="div" className="text-error" />
                            </jnb.InputGroup>
                        </jnb.Col>
                        <jnb.Col xs={12} sm={12} md={12} lg={2} xl={2} xxl={2}>
                            <jnb.InputGroup className="mb-4p5">
                                <span className="label-text-style"><b>Project:<font style={{ color: "red" }}>*</font></b></span>
                                <Field as="select" className="form-control mb-2" name="project_id">
                                    <option value="">--select--</option>
                                    {getprojectListData !== undefined && getprojectListData?.map((data, i) => {
                                        return <option key={i} value={data.project_id}>{data.project_name}</option>
                                    })}
                                </Field>
                                <ErrorMessage name="project_id" component="div" className="text-error" />
                            </jnb.InputGroup>
                        </jnb.Col>
                        <jnb.Col xs={12} sm={12} md={12} lg={4} xl={4} xxl={4}>
                            <jnb.InputGroup className="mb-4p5">
                                <span className="label-text-style"><b>Project Description:<font style={{ color: "red" }}>*</font></b></span>
                                <Field as="textarea" className="form-control mb-2" style={{ height: '100px' }} name="remarks" maxLength="200"
                                onChange={handleRemarksChange}  // Custom onChange handler
                                ></Field>
                                <ErrorMessage name="remarks" component="div" className="text-error" />
                            </jnb.InputGroup>
                        </jnb.Col>
                        <jnb.Row>
                            <jnb.Col xs={12} sm={12} md={12} lg={8} xl={8} xxl={8} className="mb-2 text-end"></jnb.Col>
                            <jnb.Col xs={12} sm={12} md={12} lg={1} xl={1} xxl={1} className="mb-2 text-end">
                                <button type="submit" className="btn btn-success rounded">Submit</button>
                            </jnb.Col>
                        </jnb.Row>
                    </jnb.Row>
                    {getWorkstatusprojectListData?.length > 0 ? <>
                    <jnb.Row className="pt-2 border m-0">
                        <jnb.Row className="px-4 m-0 pt-1">
                            <jnb.Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
                                <div className="head-text" style={{ display: "inline" }}>
                                    <h1>Daily Work Status Report</h1> </div>
                            </jnb.Col>
                            <jnb.Col xs={12} sm={12} md={12} lg={2} xl={2} xxl={2}></jnb.Col>
                            <jnb.Col xs={12} sm={12} md={12} lg={2} xl={2} xxl={2}>
                                <jnb.InputGroup>
                                    <input className="form-control" id="search" name={`search_${Date.now()}`} type="text" placeholder="search" autoComplete="off"
                                        onChange={event => setSearch(event.target.value)}
                                    />
                                </jnb.InputGroup>
                            </jnb.Col>

                        </jnb.Row>
                        <jnb.Row className="px-5 pt-2 mb-3">
                        <table className="table table-condensed table-bordered table-responsive mb-0 "
                            style={{ border: '1px solid lightblue', width: '90%', borderCollapse: 'collapse', }}>
                            <thead>
                                <tr>
                                    <th align="center" style={{ width: "1%" }}>S.No</th>
                                    <th align="center" style={{ width: "9%" }}>Date</th>
                                    <th style={{ width: "20%" }}>Project Name</th>
                                    <th>Project Description</th>
                                </tr>
                            </thead>
                            <tbody>
                                {getWorkstatusprojectListData && getWorkstatusprojectListData?.filter((element) =>
                                    element?.project_name?.toLowerCase().includes(search.toLowerCase()) ||
                                    element?.remarks?.toLowerCase().includes(search.toLowerCase()) ||
                                    element?.work_date?.toString().toLowerCase().includes(search.toLowerCase()))?.map((data, i) => (
                                        <tr key={i}>
                                            <td>{i + 1}</td>
                                            <td>{moment(data?.work_date, 'YYYY-MM-DD').format('DD-MM-YYYY')}</td>
                                            <td>{data?.project_name}</td>
                                            <td>{data?.remarks}</td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                        </jnb.Row>
                    </jnb.Row>
                    </>:null}
                </Form>
            </FormikProvider>
        </jnb.Row>
    </>)
}