
// import { FormikProvider, useFormik, Form, ErrorMessage } from "formik";
// import { Link } from "react-router-dom";
// import * as jnb from "react-bootstrap";
// import { useSelector } from "react-redux";
// import * as Yup from "yup";
// import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
// import { FaFileAlt } from 'react-icons/fa';

// export default function DocumentUpload() {

//     const logindetailsLeavejobposting = useSelector((stateleavereq) => stateleavereq?.reducers?.loginreducer?.userLoginDetials);

//     const formIk = useFormik({
//         enableReinitialize: true,
//         initialValues: {
//             ssc_upload:"",
//             inter_upload:"",
//             degree_upload:"",
//             job_experience:"",
//             relieving_files:"",
//             bankpassbook_upload:"",
//             pan_upload:"",
//             aadhar:"",
//         },
//         validationSchema:Yup.object().shape({
//             ssc_upload:Yup.string().required("Required").nullable(),
//             inter_upload:Yup.string().required("Required").nullable(),
//             degree_upload:Yup.string().required("Required").nullable(),
//             relieving_files:Yup.string().required("Required").nullable(),
//             aadhar:Yup.string().required("Required").nullable(),
//             pan_upload:Yup.string().required("Required").nullable(),
//             bankpassbook_upload:Yup.string().required("Required").nullable(),
//         }),
//         onSubmit: (values) => {
//             console.log(null)
//         },
//     });
//     return (<>
//         <jnb.Row className="m-0">
//             <jnb.Col xs={12} sm={12} md={12} lg={11} xl={11} xxl={11}>
//                 <ol className="breadcrumb">
//                     <li className="breadcrumb-item">
//                         <Link to="/homepage">Home</Link>
//                     </li>
//                     <li className="breadcrumb-item active" aria-current="page">Upload Documents </li>
//                 </ol>
//             </jnb.Col>
//         </jnb.Row>
//         <div className="head-text mt-3"> <h1>Upload Documents</h1> </div>
//         <FormikProvider value={formIk}>
//             <Form onSubmit={formIk.handleSubmit} onChange={formIk.handleChange}>
                
//                 <jnb.Row className="px-3 pt-2">
//                 <Tabs>
//             <TabList>
//               <Tab><FaFileAlt className="d-block m-auto fs-6 text-center mb-1" />Education Documents</Tab>
//               <Tab><FaFileAlt className="d-block m-auto fs-6 text-center mb-1" />Job Experienced Documents</Tab>
//               <Tab><FaFileAlt className="d-block m-auto fs-6 text-center mb-1" />Other Documents</Tab>
//             </TabList>
//             <TabPanel>
//             <jnb.Row className="px-3 pt-4">
//             <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
//                         <jnb.InputGroup className="mb-4p5">
//                             <span className="label-text-style"><b>SSC:<font style={{ color: "red" }}>*</font> </b></span>
//                             <input type="file" name="ssc_upload" accept="image/jpeg,jpg" className="form-control" onChange={formIk.handleChange} />
//                             <ErrorMessage name="ssc_upload" component="div" className="text-error" />
//                         </jnb.InputGroup>
//                     </jnb.Col>

//                     {/* Intermediate Upload */}
//                     <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
//                         <jnb.InputGroup className="mb-4p5">
//                             <span className="label-text-style"><b>Intermediate:<font style={{ color: "red" }}>*</font> </b></span>
//                             <input type="file" name="inter_upload" accept="image/jpeg,jpg" className="form-control" onChange={formIk.handleChange} />
//                             <ErrorMessage name="inter_upload" component="div" className="text-error" />
//                         </jnb.InputGroup>
//                     </jnb.Col>

//                     {/* Degree Upload */}
//                     <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
//                         <jnb.InputGroup className="mb-4p5">
//                             <span className="label-text-style"><b>Degree:<font style={{ color: "red" }}>*</font> </b></span>
//                             <input type="file" name="degree_upload" accept="image/jpeg,jpg" className="form-control" onChange={formIk.handleChange} />
//                             <ErrorMessage name="degree_upload" component="div" className="text-error" />
//                         </jnb.InputGroup>
//                     </jnb.Col>
//                 </jnb.Row>

//             </TabPanel>
//             <TabPanel>
//             <jnb.Row className="px-3 pt-4">
//                     <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
//                         <jnb.InputGroup className="mb-4p5">
//                             <span className="label-text-style"><b>Job Experience Files:<font style={{ color: "red" }}>*</font> </b></span>
//                             <input type="file" name="job_experience" accept="image/jpeg,jpg" className="form-control" multiple onChange={formIk.handleChange} />
//                             <ErrorMessage name="job_experience" component="div" className="text-error" />
//                         </jnb.InputGroup>
//                     </jnb.Col>
//                     <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
//                         <jnb.InputGroup className="mb-4p5">
//                             <span className="label-text-style"><b>Relieving Documents:<font style={{ color: "red" }}>*</font> </b></span>
//                             <input type="file" name="relieving_files" accept="image/jpeg,jpg" className="form-control" multiple onChange={formIk.handleChange} />
//                             <ErrorMessage name="relieving_files" component="div" className="text-error" />
//                         </jnb.InputGroup>
//                     </jnb.Col>
//                 </jnb.Row>
//             </TabPanel>
//             <TabPanel>
//             <jnb.Row className="px-3 pt-4">
//                     <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
//                         <jnb.InputGroup className="mb-4p5">
//                             <span className="label-text-style"><b>Aadhar:<font style={{ color: "red" }}>*</font> </b></span>
//                             <input type="file" name="aadhar" accept="image/jpeg,jpg" className="form-control" multiple onChange={formIk.handleChange} />
//                             <ErrorMessage name="aadhar" component="div" className="text-error" />
//                         </jnb.InputGroup>
//                     </jnb.Col>
//                     <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
//                         <jnb.InputGroup className="mb-4p5">
//                             <span className="label-text-style"><b>PAN:<font style={{ color: "red" }}>*</font> </b></span>
//                             <input type="file" name="pan_upload" accept="image/jpeg,jpg" className="form-control" multiple onChange={formIk.handleChange} />
//                             <ErrorMessage name="pan_upload" component="div" className="text-error" />
//                         </jnb.InputGroup>
//                     </jnb.Col>
//                     <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
//                         <jnb.InputGroup className="mb-4p5">
//                             <span className="label-text-style"><b>Bank Passbook:<font style={{ color: "red" }}>*</font> </b></span>
//                             <input type="file" name="bankpassbook_upload" accept="image/jpeg,jpg" className="form-control" multiple onChange={formIk.handleChange} />
//                             <ErrorMessage name="bankpassbook_upload" component="div" className="text-error" />
//                         </jnb.InputGroup>
//                     </jnb.Col>
//                 </jnb.Row>
//             </TabPanel>
//           </Tabs>
              
//                 </jnb.Row>
//             </Form>
//         </FormikProvider>
//     </>)
// }
import { FormikProvider, useFormik, Form, ErrorMessage } from "formik";
import { Link } from "react-router-dom";
import * as jnb from "react-bootstrap";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { FaFileAlt } from 'react-icons/fa';
import { useState } from "react";

export default function DocumentUpload() {

    const logindetailsLeavejobposting = useSelector((stateleavereq) => stateleavereq?.reducers?.loginreducer?.userLoginDetials);

    const formIk = useFormik({
        enableReinitialize: true,
        initialValues: {
            ssc_upload: "",
            inter_upload: "",
            degree_upload: "",
            job_experience: "",
            relieving_files: "",
            bankpassbook_upload: "",
            pan_upload: "",
            aadhar: "",
        },
        validationSchema: Yup.object().shape({
            ssc_upload: Yup.string().required("Required").nullable(),
            inter_upload: Yup.string().required("Required").nullable(),
            degree_upload: Yup.string().required("Required").nullable(),
            relieving_files: Yup.string().required("Required").nullable(),
            aadhar: Yup.string().required("Required").nullable(),
            pan_upload: Yup.string().required("Required").nullable(),
            bankpassbook_upload: Yup.string().required("Required").nullable(),
        }),
        onSubmit: (values) => {
            console.log("Form submitted", values);
            // Submit the form data to the server here
        },
    });

    // State to track whether tabs are enabled
    const [tabIndex, setTabIndex] = useState(0);

    const handleTabSelect = (index) => {
        // if (index === 1 && !formIk.isValid) return; // Prevent switching to the second tab if first tab is not valid
        // if (index === 2 && !formIk.isValid) return; // Prevent switching to third tab if both first and second tabs are not valid
        setTabIndex(index);
    };

    return (
        <>
            <jnb.Row className="m-0">
                <jnb.Col xs={12} sm={12} md={12} lg={11} xl={11} xxl={11}>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <Link to="/homepage">Home</Link>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">Upload Documents</li>
                    </ol>
                </jnb.Col>
            </jnb.Row>
            <div className="head-text mt-3"> <h1>Upload Documents</h1> </div>
            <FormikProvider value={formIk}>
                <Form onSubmit={formIk.handleSubmit} onChange={formIk.handleChange}>
                    <jnb.Row className="px-3 pt-2">
                        <Tabs selectedIndex={tabIndex} onSelect={handleTabSelect}>
                            <TabList>
                                <Tab>
                                    <FaFileAlt className="d-block m-auto fs-6 text-center mb-1" />Education Documents
                                </Tab>
                                <Tab>
                                    <FaFileAlt className="d-block m-auto fs-6 text-center mb-1" />Job Experienced Documents
                                </Tab>
                                <Tab>
                                    <FaFileAlt className="d-block m-auto fs-6 text-center mb-1" />Other Documents
                                </Tab>
                            </TabList>
                            <TabPanel>
                                <jnb.Row className="px-3 pt-4">
                                    <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                                        <jnb.InputGroup className="mb-4p5">
                                            <span className="label-text-style"><b>SSC:<font style={{ color: "red" }}>*</font> </b></span>
                                            <input type="file" name="ssc_upload" accept="image/jpeg,jpg" className="form-control" onChange={formIk.handleChange} />
                                            <ErrorMessage name="ssc_upload" component="div" className="text-error" />
                                        </jnb.InputGroup>
                                    </jnb.Col>

                                    {/* Intermediate Upload */}
                                    <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                                        <jnb.InputGroup className="mb-4p5">
                                            <span className="label-text-style"><b>Intermediate:<font style={{ color: "red" }}>*</font> </b></span>
                                            <input type="file" name="inter_upload" accept="image/jpeg,jpg" className="form-control" onChange={formIk.handleChange} />
                                            <ErrorMessage name="inter_upload" component="div" className="text-error" />
                                        </jnb.InputGroup>
                                    </jnb.Col>

                                    {/* Degree Upload */}
                                    <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                                        <jnb.InputGroup className="mb-4p5">
                                            <span className="label-text-style"><b>Degree:<font style={{ color: "red" }}>*</font> </b></span>
                                            <input type="file" name="degree_upload" accept="image/jpeg,jpg" className="form-control" onChange={formIk.handleChange} />
                                            <ErrorMessage name="degree_upload" component="div" className="text-error" />
                                        </jnb.InputGroup>
                                    </jnb.Col>
                                </jnb.Row>
                            </TabPanel>
                            <TabPanel>
                                <jnb.Row className="px-3 pt-4">
                                    <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                                        <jnb.InputGroup className="mb-4p5">
                                            <span className="label-text-style"><b>Job Experience Files:<font style={{ color: "red" }}>*</font> </b></span>
                                            <input type="file" name="job_experience" accept="image/jpeg,jpg" className="form-control" multiple onChange={formIk.handleChange} />
                                            <ErrorMessage name="job_experience" component="div" className="text-error" />
                                        </jnb.InputGroup>
                                    </jnb.Col>
                                    <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                                        <jnb.InputGroup className="mb-4p5">
                                            <span className="label-text-style"><b>Relieving Documents:<font style={{ color: "red" }}>*</font> </b></span>
                                            <input type="file" name="relieving_files" accept="image/jpeg,jpg" className="form-control" multiple onChange={formIk.handleChange} />
                                            <ErrorMessage name="relieving_files" component="div" className="text-error" />
                                        </jnb.InputGroup>
                                    </jnb.Col>
                                </jnb.Row>
                            </TabPanel>
                            <TabPanel>
                                <jnb.Row className="px-3 pt-4">
                                    <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                                        <jnb.InputGroup className="mb-4p5">
                                            <span className="label-text-style"><b>Aadhar:<font style={{ color: "red" }}>*</font> </b></span>
                                            <input type="file" name="aadhar" accept="image/jpeg,jpg" className="form-control" multiple onChange={formIk.handleChange} />
                                            <ErrorMessage name="aadhar" component="div" className="text-error" />
                                        </jnb.InputGroup>
                                    </jnb.Col>
                                    <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                                        <jnb.InputGroup className="mb-4p5">
                                            <span className="label-text-style"><b>PAN:<font style={{ color: "red" }}>*</font> </b></span>
                                            <input type="file" name="pan_upload" accept="image/jpeg,jpg" className="form-control" multiple onChange={formIk.handleChange} />
                                            <ErrorMessage name="pan_upload" component="div" className="text-error" />
                                        </jnb.InputGroup>
                                    </jnb.Col>
                                    <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                                        <jnb.InputGroup className="mb-4p5">
                                            <span className="label-text-style"><b>Bank Passbook:<font style={{ color: "red" }}>*</font> </b></span>
                                            <input type="file" name="bankpassbook_upload" accept="image/jpeg,jpg" className="form-control" multiple onChange={formIk.handleChange} />
                                            <ErrorMessage name="bankpassbook_upload" component="div" className="text-error" />
                                        </jnb.InputGroup>
                                    </jnb.Col>
                                </jnb.Row>
                            </TabPanel>
                        </Tabs>
                    </jnb.Row>
                    <div className="d-flex justify-content-end">
                        <button type="submit" className="btn btn-primary" disabled={tabIndex !== 2}>Submit</button>
                    </div>
                </Form>
            </FormikProvider>
        </>
    );
}
