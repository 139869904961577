import { ErrorMessage, Field, Form, FormikProvider, useFormik } from 'formik'
import React, { useEffect, useRef, useState } from 'react'
import * as Yup from 'yup'
import * as jnb from 'react-bootstrap'
import { Link } from 'react-router-dom'
import CommonPost from '../../../CommonUtils/CommonPost'
import Swal from 'sweetalert2'
import { config } from '../../../CommonUtils/CommonApis'
import { CommonAxiosGet } from '../../../CommonUtils/CommonAxios'
import Sweetalert from '../../../CommonUtils/SweetAlerts'
import { useSelector } from 'react-redux'
import { useReactToPrint } from 'react-to-print';
import { SiMicrosoftexcel } from 'react-icons/si'
import { BsPrinter } from 'react-icons/bs'
import ReactHtmlTableToExcel from 'react-html-table-to-excel';
export default function AddProjectNames() {
    const logindetailsAtAddproject = useSelector((state) => state.reducers?.loginreducer?.userLoginDetials)
    const [getProjectnameData, setProjectNameData] = useState([]);
    const [search, setSearch] = useState("");
    const [showModelPopUp, setModelPopUp] = useState(false);

    const addprojectFormik = useFormik({
        initialValues: {
            project_name: "",
            project_manaqger_id: logindetailsAtAddproject?.username
        },
        validationSchema: Yup.object().shape({
            project_name: Yup.string().required("Required")
        }),
        onSubmit: (values) => {
            const finalvalues = {
                project_name: values?.project_name,
                project_manaqger_id: logindetailsAtAddproject?.username
            }
            Swal.fire({
                title: 'Are you sure you want to submit?',
                text: 'Please check it once before submitting',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Submit',
                cancelButtonText: 'Cancel',
                reverseButtons: true
            }).then((result) => {
                if (result.value) {
                    CommonPost.saveAddprojectnames(finalvalues)
                        .then((res) => {
                            if (res.data.ResponseCode === "01") {
                                Swal.fire({
                                    title: res?.data?.ResponseDesc,
                                    icon: "success",
                                    backdrop: false
                                }).then((resforDesc) => {
                                    if (resforDesc?.isConfirmed === true) {
                                        GetAddedProjectNameDetails()
                                        addprojectFormik?.setFieldValue("project_name", "");
                                        setModelPopUp(false)
                                    }
                                })
                            }
                            else if (res.data.ResponseCode === "02") {
                                Sweetalert(res.data.ResponseDesc, 'warning');
                            }
                            else {
                                Swal.fire('Canceled', 'Something went wrong,Please check', 'error');
                            }
                        })
                        .catch(() => { console.log("Exception Occured 71 "); });
                } else if (result.dismiss === Swal.DismissReason.cancel) {
                    Swal.fire('Canceled', 'Do corrections and then retry :)', 'error');
                }
            })
        },
    })
    function GetAddedProjectNameDetails() {
        let projectnameUrl = config.url.COMMON_URL + "getprojectsro/" + logindetailsAtAddproject?.username;
        CommonAxiosGet(projectnameUrl).then((res) => {
            if (res?.data?.status === true) {
                setProjectNameData(res?.data?.getprojectsro)
            }
            else {
                setProjectNameData([])
            }
        })
    }
    let addprojectcomponentRef = useRef();
    const addprojectHandleprint = useReactToPrint({
        content: () => addprojectcomponentRef.current,
        documentTitle: "Projects Report",
    });
    const addprojectref = useRef(false)
    useEffect(() => {
        if (!addprojectref.current) {
            GetAddedProjectNameDetails()
            addprojectref.current = true
        }
    }, [])
    const handleClose = () => {setModelPopUp(false);}
    function  showStatusInModel(){
        setModelPopUp(true);
    }
    return (
        <div>
            <jnb.Row className="px-3 mb-2 pt-3" >
                <jnb.Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <Link to="/homepage">Home</Link>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">Add Projects</li>
                    </ol>
                </jnb.Col>
            </jnb.Row>
            <jnb.Row className=" pt-2">
            <jnb.Modal show={showModelPopUp} onHide={handleClose} className="modal-md" backdrop="static">
           <jnb.Modal.Header className="bg-success bg-opacity-75 text-white" closeButton>
               <jnb.Modal.Title>Add Project</jnb.Modal.Title>
           </jnb.Modal.Header>
           <jnb.Modal.Body>
           </jnb.Modal.Body>
           <jnb.Row className="px-3 pt-2">
           <FormikProvider value={addprojectFormik}>
                    <Form onSubmit={addprojectFormik.handleSubmit} onChange={addprojectFormik.handleChange}>
                        <jnb.Row >
                            <jnb.Col xs={12} sm={12} md={6} lg={8} xl={8} xxl={8}>
                                <jnb.InputGroup className="mb-4p5">
                                    <span className="label-text-style"><b>Name of the Project<font style={{ color: "red" }}>*</font></b></span>
                                    <Field type="text" className="form-control mb-2" name="project_name" />
                                    <ErrorMessage name="project_name" component="div" className="text-error" />
                                </jnb.InputGroup>
                            </jnb.Col>
                            <jnb.Col xs={12} sm={12} md={12} lg={1} xl={1} xxl={1} className="mb-2 text-end">
                                <button type="submit" className="btn btn-success rounded">Submit</button>
                            </jnb.Col>
                           
                        </jnb.Row>
                        </Form>
                        </FormikProvider>
                        </jnb.Row>
           </jnb.Modal>
                        <jnb.Row className="px-5 m-0 pt-1">
                        <jnb.Col xs={12} sm={12} md={12} lg={2} xl={2} xxl={2}>
                                <jnb.InputGroup>
                                <button type="button" class="btn btn-outline-success  float-end rounded" style={{fontSize:"25px"}}
                       onClick={()=>{showStatusInModel()}}>Add Project</button>
                                </jnb.InputGroup>
                            </jnb.Col>
                        {getProjectnameData?.length > 0 ?<>
                            <jnb.Col xs={12} sm={12} md={12} lg={2} xl={2} xxl={2}>
                                <jnb.InputGroup>
                                    <input className="form-control" id="search" name={`search_${Date.now()}`} type="text" placeholder="search" autoComplete="off"
                                        onChange={event => setSearch(event.target.value)}
                                    />
                                </jnb.InputGroup>
                            </jnb.Col>

                            <jnb.Col xs={12} sm={12} md={12} lg={1} xl={1} xxl={1} className='excel-print'>
                                <div style={{ float: 'right' }}>
                                    <ReactHtmlTableToExcel
                                        id="test-table-xls-button"
                                        className="pull-right btn btn-sm btn-success"
                                        table="table-to-xls"
                                        filename={"Projects Report"}
                                        sheet="tablexls"
                                        buttonText={<><SiMicrosoftexcel size={20} title="Excel" /> Excel</>}
                                    /> </div> </jnb.Col>
                                  
                                    <jnb.Col xs={12} sm={12} md={12} lg={1} xl={1} xxl={1} className='excel-print'>
                                    <button type="button" className="btn btn-primary btn-sm" onClick={addprojectHandleprint}>
                                        <BsPrinter title="Print" size={"20px"} /> Print
                                    </button>
                                
                            </jnb.Col> </>:null}
                           
                        </jnb.Row>
                       
                        {getProjectnameData?.length > 0 ?
                            <jnb.Row className="px-5 pt-2 mb-3">
                                {/* <center> */}
                                <jnb.Container>
                                    <div style={{ maxHeight: '10px', width: '50%', }}>
                                        <table className="sticky-table" ref={addprojectcomponentRef} id="table-to-xls"
                                            style={{ border: '1px solid lightblue', borderCollapse: 'collapse', }}>
                                            <thead className="sticky-header">
                                                <tr>
                                                    <th className="sticky-header" align="center" style={{ width: "1%" }}>S.No</th>
                                                    <th className="sticky-header" style={{ width: "20%" }}>Project Name</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {getProjectnameData && getProjectnameData?.filter((element) =>
                                                    element?.project_name?.toString().toLowerCase().includes(search.toLowerCase()))?.map((data, i) => (
                                                        <tr key={i}>
                                                            <td>{i + 1}</td>
                                                            <td>{data?.project_name}</td>
                                                        </tr>
                                                    ))}
                                            </tbody>
                                        </table>
                                    </div>
                                    </jnb.Container>
                                    {/* </center> */}
                            </jnb.Row> : null}
                  
            </jnb.Row>
        </div>
    )
}
