import { ErrorMessage, Field, FieldArray, FormikProvider, useFormik, Form } from "formik";
import { Link, useNavigate } from "react-router-dom";
import * as jnb from "react-bootstrap";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import React, { useState, useEffect } from "react";
import { BiTrash } from "react-icons/bi";
import { VscDiffAdded } from "react-icons/vsc";
import { CommonAxiosGet } from "../../../../CommonUtils/CommonAxios";
import { config } from "../../../../CommonUtils/CommonApis";
import CommonPost from "../../../../CommonUtils/CommonPost";
import Sweetalert from "../../../../CommonUtils/SweetAlerts";
export default function UpdateStatus() {

    const navigate = useNavigate()

    const logindetails = useSelector((state) => state.reducers?.loginreducer?.userLoginDetials);
    const candidatedetails = JSON.parse(localStorage.getItem("candidatedetails"));
    const [showUpdateStatusLength, setUpdateStatusLength] = useState();
    const [getInterviewLevelDetails, setInterviewLevelDetails] = useState([])
    const [getFinalStatus,setFinalStatus]=useState([])
    const candidateentryInitailValues = {
        job_appl_id: candidatedetails?.job_appl_id,
        designation_applied: candidatedetails?.designation_applied,
        technology_applied: candidatedetails?.technology_applied,
        insertby: logindetails?.username,
        updateStatus: [{
            level: "1", employee_name: "",
            applicant_id: "", slno: "true",
        }]
    };

    const formIk = useFormik({
        enableReinitialize: true,
        initialValues: candidateentryInitailValues,
        onSubmit: (values) => {
            console.log(values);
            const freshData = values.updateStatus.filter(item => item.applicant_id === "");
            const formikvalues = {
                job_appl_id: candidatedetails?.job_appl_id,
                designation_applied: candidatedetails?.designation_applied,
                technology_applied: candidatedetails?.technology_applied,
                insertby: logindetails?.username,
                updateStatus: freshData
            }
            const filterdata = values.updateStatus.filter(item => item.slno === "true");
            const updatedValues = {
                ...values,
                updateStatus: filterdata,
            };
            console.log("updatedValues", updatedValues)

            InterviewDetailsSave(updatedValues);
        },
    });

    const finalformIk = useFormik({
        enableReinitialize: true,
        initialValues: {
            job_appl_id: candidatedetails?.job_appl_id,
            final_status_inserted_by: logindetails?.username,
            final_status: "",
            final_status_remarks: "",
        },
        onSubmit: (values) => {
            FinalSave(values)
        },

    });
    function NavigateToJobPosReport() {
        navigate("/appliedCandidateDetails")
    }
    function GetStatusData() {
        CommonAxiosGet(config.url.COMMON_URL + "jobinterviewdtls/" + candidatedetails?.job_appl_id).then((res) => {
          
            if (res.data.interviewdataStatus === true) {
                setUpdateStatusLength(res.data.interviewdata);
              
                res.data.interviewdata.forEach((interview, index) => {
                    formIk.setFieldValue(`updateStatus[${index}].applicant_id`, interview.applicant_id);
                    formIk.setFieldValue(
                        `updateStatus[${index}].level`,
                        `${formIk.values.updateStatus.length + 1}` // Dynamic level based on array length + 1
                    );
                    formIk.setFieldValue(
                        `updateStatus[${index}].employee_name`,
                        interview.interview_conduted_by_name + ""
                    );
                    formIk.setFieldValue(`updateStatus[${index}].remarks`, interview.remarks_of_interview);
                    formIk.setFieldValue(`updateStatus[${index}].status`, interview.status_of_interview);
                    formIk.setFieldValue(`updateStatus[${index}].slno`, interview.slno);
                });
            }
            if (res?.data?.interviewlevelsStatus === true) {
                setInterviewLevelDetails(res?.data?.interviewlevels)
            }
            if (res?.data?.final_statusStatus === true) {
                finalformIk.setFieldValue("final_status", res.data.final_status[0].final_status+"")
                finalformIk.setFieldValue("final_status_remarks",res.data.final_status[0].final_status_remarks);
            }
            if (res?.data?.finalintervewstatusStatus === true) {
                setFinalStatus(res.data.finalintervewstatus[0].statusofinterview);
            }
            else {
                setInterviewLevelDetails([])
            }

        })
    }
    useEffect(() => {
        GetStatusData()
    }, [])
    const InterviewDetailsSave = (values) => {
        Swal.fire({
            title: 'Are you sure you want to submit?',
            text: 'Please check it once before submitting',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Submit',
            cancelButtonText: 'Cancel',
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                CommonPost.saveinterviewdlsPost(values)
                    .then((res) => {
                        console.log("candidate data", res.data)
                        if (res.data.ResponseCode === "01") {
                            // Swal.fire('Successfully Submitted', 'Success', 'success',);

                            Swal.fire({
                                title: res?.data?.ResponseDesc,
                                icon: "success",
                                backdrop: false
                            }).then((res) => {
                                if (res?.isConfirmed === true) {
                                    GetStatusData()
                                    formIk?.resetForm({
                                        values: {
                                            job_appl_id: candidatedetails?.job_appl_id,
                                            designation_applied: candidatedetails?.designation_applied,
                                            technology_applied: candidatedetails?.technology_applied,
                                            insertby: logindetails?.username,
                                            updateStatus: [{
                                                level: "1", employee_name: "",
                                                applicant_id: "", slno: ""
                                            }]
                                        }
                                    });
                                }
                            });
                        }
                        else if (res.data.ResponseCode === "02") {
                            Sweetalert(res.data.ResponseDesc, 'warning');
                        }
                        else if (res.data.ResponseCode === "04") {
                            Sweetalert(res.data.ResponseDesc);
                        }
                        else {
                            Swal.fire('Canceled', 'Something went wrong,Please check', 'error');
                        }
                    })
                    .catch(() => {
                        console.log("Exception Occured 71 ");
                    });
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire('Canceled', 'Do corrections and then retry :)', 'error');
            }
        })
    };
    const FinalSave = (values) => {
        Swal.fire({
            title: 'Are you sure you want to submit?',
            text: 'Please check it once before submitting',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Submit',
            cancelButtonText: 'Cancel',
            reverseButtons: true
        }).then((result) => {
            const levels_interview = showUpdateStatusLength?.length;
            const final_status = finalformIk.values.final_status;
            const final_status_remarks = finalformIk.values.final_status_remarks;
            const final_status_inserted_by = logindetails?.username
            const job_appl_id = candidatedetails?.job_appl_id;

            if (result.value) {
                CommonPost.finalsave(levels_interview, final_status, final_status_remarks, final_status_inserted_by, job_appl_id)
                    .then((res) => {
                        console.log("candidate data", res.data)
                        if (res.data.ResponseCode === "01") {
                            // Swal.fire('Successfully Submitted', 'Success', 'success',);
                            Swal.fire({
                                title: res?.data?.ResponseDesc,
                                icon: "success",
                                backdrop: false
                            }).then((res) => {
                                if (res?.isConfirmed === true) {
                                    GetStatusData()

                                    formIk?.resetForm({
                                        values: {
                                            job_appl_id: candidatedetails?.job_appl_id,
                                            designation_applied: candidatedetails?.designation_applied,
                                            technology_applied: candidatedetails?.technology_applied,
                                            insertby: logindetails?.username,
                                            updateStatus: [{
                                                level: "1", employee_name: "",
                                                applicant_id: ""
                                            }]
                                        }
                                    })
                                }
                            })
                        }
                        else if (res.data.ResponseCode === "02") {
                            Sweetalert(res.data.ResponseDesc, 'warning');
                        }
                        else if (res.data.ResponseCode === "04") {
                            Sweetalert(res.data.ResponseDesc);
                        }
                        else {
                            Swal.fire('Canceled', 'Something went wrong,Please check', 'error');
                        }
                    })
                    .catch(() => {
                        console.log("Exception Occured 71 ");
                    });
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire('Canceled', 'Do corrections and then retry :)', 'error');
            }
        })
    };
    const [showEmpLevel, setEmpLevel] = useState([])
    useEffect(() => {
        let Url = config.url.COMMON_URL + "getempdata";
        CommonAxiosGet(Url).then((res) => {
            if (res.data.status === true) {
                setEmpLevel(res.data.employeedata)

            }
            else { setEmpLevel([]) }
        })
    }, []);

const groupedData = getInterviewLevelDetails
  ? getInterviewLevelDetails.reduce((acc, item) => {
      const key = `${item.emp_name}-${item.level_of_interview}`;
      if (!acc[key]) {
        acc[key] = {
          emp_name: item.emp_name,
          level_of_interview: item.level_of_interview,
          details: [],
        };
      }
      acc[key].details.push({
        category: item.category,
        score: item.score,
      });
      return acc;
    }, {})
  : {};

    return (<>

        <jnb.Row className="px-3 mb-2 pt-3" >
            <jnb.Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <Link to="/homepage">Home</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">Job Application-{candidatedetails?.zone_name}</li>
                </ol>
            </jnb.Col>
            <jnb.Col xs={12} sm={12} md={12} lg={5} xl={5} xxl={5}>
                <button type="button" class="btn btn-secondary  btn-sm float-end"
                    onClick={() => { NavigateToJobPosReport() }}>Back</button>
            </jnb.Col>
        </jnb.Row>
        <jnb.Row className="px-3 mb-2 pt-3" >
            <jnb.Col xs={12} sm={12} md={12} lg={4} xl={4} xxl={4}>
                <div class="card">
                    <div class="card-header" style={{ backgroundColor: '#d6eaf8', color: 'black', }}>Job Post Details</div>
                    <div class="card-body">
                        <jnb.Row>
                            <jnb.Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
                                <label className="w-100"> <b>Technology:</b>&nbsp;&nbsp;</label>
                                <i style={{ textAlign: "justify" }}>{candidatedetails?.zone_name}</i>
                            </jnb.Col>
                            <jnb.Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
                                <label className="w-100"><b>Designation:</b>&nbsp;&nbsp;</label>
                                <i style={{ textAlign: "justify" }}>{candidatedetails?.designation_name}</i>
                            </jnb.Col>
                        </jnb.Row>
                    </div>
                </div>
            </jnb.Col>
            <jnb.Col xs={12} sm={12} md={12} lg={7} xl={7} xxl={7}>
                <div class="card">
                    <div class="card-header" style={{ backgroundColor: '#d5f5e3', color: 'black' }}>Candidate Details</div>
                    <div class="card-body">
                        <jnb.Row>
                            <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                                <label className="w-100"><b>Name:</b>&nbsp;&nbsp;</label>
                                <i style={{ textAlign: "justify" }}>{candidatedetails?.candidate_name}
                                </i>
                            </jnb.Col>
                            <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                                <label className="w-100"><b>Present Designation:</b></label>
                                <i style={{ textAlign: "justify" }}>{candidatedetails?.presnet_designation}
                                </i>
                            </jnb.Col>
                            <jnb.Col xs={12} sm={12} md={12} lg={2} xl={2} xxl={2}>
                                <label className="w-100"><b>Mobile:</b></label>
                                <i style={{ textAlign: "justify" }}>&nbsp;&nbsp;{candidatedetails?.mobile}
                                </i>
                            </jnb.Col>
                            <jnb.Col xs={12} sm={12} md={12} lg={2} xl={2} xxl={2}>
                                <label className="w-100"> <b>Experience:</b></label>
                                <i style={{ textAlign: "justify" }}>&nbsp;&nbsp;{candidatedetails?.years_of_experience}
                                </i>
                            </jnb.Col>
                            <jnb.Col xs={12} sm={12} md={12} lg={2} xl={2} xxl={2}>
                                <label className="w-100"> <b>Email:</b></label>
                                <i style={{ textAlign: "justify" }}>&nbsp;{candidatedetails?.email}
                                </i>
                            </jnb.Col>
                        </jnb.Row>
                    </div>
                </div>
            </jnb.Col>
        </jnb.Row>

        <FormikProvider value={formIk}>
            <Form onSubmit={formIk.handleSubmit} onChange={formIk.handleChange}>

                <Field type="hidden" name="technology" className="form-control" />
                <jnb.Row className="px-4 mb-2">
                <div className="border pt-1 rounded" style={{backgroundColor:"#faf7f1"}}>
                <jnb.Row className="px-3 pt-1">
                    <jnb.Col xs={12} sm={12} md={12} lg={4} xl={4} xxl={4}>
                        <div className="table-responsive">
                            <table className="table table-condensed table-bordered table-striped table-responsive"
                                style={{ border: '1px solid lightblue', borderCollapse: 'collapse' }}>
                                <thead>
                                    <tr>
                                        <th>Level</th>
                                        <th>Employee</th>
                                        {/* <th>Remarks</th>
                                <th>Status</th> */}
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody style={{ textAlign: "center" }}>
                                    <FieldArray name="updateStatus">
                                        {(formikFieldArray) => {
                                            return (
                                                formikFieldArray.form.values.updateStatus.map(
                                                    (qual, index) => {
                                                        return (
                                                            <>
                                                                <tr key={index}>
                                                                    <Field type="hidden" className="form-control" name={`[updateStatus[${index}].applicant_id`} ></Field>
                                                                    <td>{index + 1}<Field type="hidden" className="form-control" name={`[updateStatus[${index}].level`} value={`${index + 1}`}></Field>
                                                                        <ErrorMessage name={`[updateStatus[${index}].level`} component="div" className="text-danger" ></ErrorMessage>
                                                                    </td>
                                                                    <td>
                                                                        <jnb.Col xs={12} sm={12} md={12} lg={4} xl={4} xxl={4}></jnb.Col>
                                                                        <Field as="select" className='form-control' name={`[updateStatus[${index}].employee_name`}>
                                                                            <option value="">--Select--</option>
                                                                            {showEmpLevel != undefined && showEmpLevel?.map((datasreqlevel, reqIndexlevel) => {
                                                                                return (<React.Fragment key={reqIndexlevel}>
                                                                                    <option key={reqIndexlevel} value={datasreqlevel.cfms_id}>
                                                                                        {datasreqlevel.emp_name}
                                                                                    </option>
                                                                                </React.Fragment>);
                                                                            })}
                                                                        </Field>
                                                                        <ErrorMessage name={`[updateStatus[${index}].employee_name`} component="div" className="text-danger" ></ErrorMessage>
                                                                    </td>

                                                                    {/* <td>
                                                            <Field as="textarea" style={{height:'30px'}} className="form-control mb-2" name={`[updateStatus[${index}].remarks`} maxLength="400"></Field>
                                                                <ErrorMessage name={`[updateStatus[${index}].remarks`} component="div" className="text-danger" ></ErrorMessage>
                                                            </td>
                                                            <td>
                                                                <Field type="text" className="form-control" name={`[updateStatus[${index}].status`} ></Field>
                                                                <ErrorMessage name={`[updateStatus[${index}].status`} component="div" className="text-danger" ></ErrorMessage>
                                                            </td> */}
                                                                    <td width="1">
                                                                        {formIk?.values?.updateStatus[index].slno === "true" ? <>
                                                                            {(index) ?
                                                                                <button type="button" className="button-titile btn btn-sm btn-danger"
                                                                                    onClick={() => { formikFieldArray.remove(index); }} >
                                                                                    <BiTrash></BiTrash>
                                                                                </button> : ''}</> : null}</td>
                                                                </tr>
                                                            </>
                                                        )
                                                    })
                                            )
                                        }}
                                    </FieldArray>
                                    <tr className='table-active'>
                                        <td align="right" colSpan={7}>
                                            <FieldArray name="updateStatus">
                                                {(formikFieldArray) => {
                                                    return formikFieldArray.form.values.updateStatus.map(
                                                        (LJP, index) => {
                                                            return (
                                                                <>
                                                                    {(index === 0) ? <>
                                                                        <button type="button" className="button-titile btn btn-sm btn-success"
                                                                            onClick={() => {
                                                                                formikFieldArray.push({
                                                                                    level: `${formikFieldArray.form.values.updateStatus.length + 1}`,
                                                                                    employee_name: "",
                                                                                    slno: "true",
                                                                                    applicant_id: ""
                                                                                });
                                                                            }}
                                                                        >
                                                                            <VscDiffAdded></VscDiffAdded> </button> </> : ''}
                                                                </>
                                                            )
                                                        })
                                                }}
                                            </FieldArray>
                                        </td>
                                    </tr>
                                </tbody >
                            </table>
                        </div>
                        {/* <jnb.Row className="px-3">
                            <div className='d-flex flex-row justify-content-end pe-0'>
                                <button type="submit" className="btn btn-success btn-sm float-end ms-2 mb-4 rounded">Submit</button>
                            </div>
                        </jnb.Row> */}
                        {getFinalStatus === 0 ? null :
                          <jnb.Row className="px-3">
                        <div className='d-flex flex-row justify-content-end pe-0'>
                                <button type="submit" className="btn btn-success btn-sm float-end ms-2 mb-4 rounded">Submit</button>
                            </div>
                        </jnb.Row> }
                    </jnb.Col>
                    <jnb.Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
                        <table
                            className="table table-condensed table-bordered table-striped table-responsive"
                            style={{ border: '1px solid lightblue', borderCollapse: 'collapse' }}>
                            <thead>
                                <tr>
                                    <th>S.No</th>
                                    <th>Interviewer Name</th>
                                    <th>Interview Level</th>
                                    <th>Category</th>
                                    <th>Score</th>
                                </tr>
                            </thead>
                            <tbody style={{ textAlign: 'left' }}>
                                {getInterviewLevelDetails &&
                                    Object.values(groupedData).map((group, index) => {
                                        return (
                                            <>
                                                <tr key={`${group.emp_name}-${group.level_of_interview}`}>
                                                    <td width="100px"
                                                        align="center" rowSpan={group.details.length + 1}>
                                                        {index + 1}
                                                    </td>
                                                    <td rowSpan={group.details.length + 1}>{group.emp_name}</td>
                                                    <td rowSpan={group.details.length + 1}>
                                                        {group.level_of_interview}
                                                    </td>
                                                </tr>
                                                {group.details.map((detail, i) => {
                                                    return (
                                                        <tr key={`${group.emp_name}-${group.level_of_interview}-${i}`} >
                                                            <td>{detail.category}</td>
                                                            <td>{detail.score}</td>
                                                        </tr>
                                                    );
                                                })}
                                            </>
                                        );
                                    })}
                            </tbody>
                        </table>
                    </jnb.Col>
                </jnb.Row>
                </div>
                </jnb.Row>
            </Form>
        </FormikProvider>
        <FormikProvider value={finalformIk}>

            <Form onSubmit={finalformIk.handleSubmit} onChange={finalformIk.handleChange}>
            { getFinalStatus === 0   ?(<>
            <jnb.Row className="px-3 pt-4">
            
            <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
            <jnb.InputGroup className="mb-4p5">
            <span className="label-text-style"><b>Final Status:<font style={{ color: "red" }}>*</font></b></span>
                <Field as="select" className='form-control' name="final_status">
                    <option value="">--Select--</option>
                    
                    <option value="Approved">Approved</option>
                    <option value="Rejected">Rejected</option>
                </Field>
                </jnb.InputGroup>
            </jnb.Col>
            <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
            <jnb.InputGroup className="mb-4p5">
                        <span className="label-text-style"><b>Final Remarks:<font style={{ color: "red" }}>*</font></b></span>
            <Field as="textarea" className="form-control mb-2" name="final_status_remarks" style={{height:'30px'}} maxLength="400"></Field>
            </jnb.InputGroup>
            </jnb.Col>
           
            
            <jnb.Col xs={12} sm={12} md={12} lg={1} xl={1} xxl={1} >
                <div className='d-flex flex-row justify-content-end ' >
                    <button type="submit" className="btn btn-success btn-sm">Save</button>
                </div>
            </jnb.Col>
            <pre></pre><pre></pre>  <pre></pre><pre></pre>
      
            </jnb.Row>
            </>):null}
            </Form>
        </FormikProvider>

    </>)
}
