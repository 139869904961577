import Swal from "sweetalert2";
import { config, IMG_UPLOAD_URL } from "./CommonApis";
import { CommonAxiosPostLogin } from "./CommonAxios";
import { InfoAlert } from "./SweetAlerts";

//pdf*********************************************
const SUPPORTED_FORMATS_FILES = ['application/pdf'];
const MAX_FILE_SIZES = 1000000;
function validateFileTypeAndSizes(customefile) {
    let errormsg = "";
    if (customefile.size > MAX_FILE_SIZES) {
        errormsg = "Please Check your file size, it should be less than 1MB\n";
    }
    if (!SUPPORTED_FORMATS_FILES.includes(customefile.type)) {
        errormsg += "File format is invalid, Please upload only .pdf files";
    }
    if (errormsg === "") {
        return true;
    }
    else {
        InfoAlert(errormsg, "info");
        return false;
    }
}

export default function onlyPdfBucket(e,formIk,path,name,filename) {

   
    e.preventDefault();
    const file = e.target.files[0]; 
    const type = file.type.split("/")[1]; 
    const newFileName = filename + "." + type; 
    const modifiedFile = new File([file], newFileName, { type: file.type });

   
    if (validateFileTypeAndSizes(file)) {
        return new Promise((resolve, reject) => {
            const formData = new FormData();
            formData.append("file", modifiedFile);
            CommonAxiosPostLogin(IMG_UPLOAD_URL + path, formData).then((response) => {
                resolve(response.data)
                
                if(response?.data !== undefined)
                {
                    formIk.setFieldValue(name, response?.data);
                }
                else{
                    Swal.fire({
                        text: "Pdf Upload Failed",
                        icon: "warning",
                        backdrop : false,
                    }).then((refresh) => {
                    
                        if (refresh.isConfirmed) {
                            formIk.setFieldValue(name, "");
                        }
                    })
                    
                }
            }).catch((error) => {
                console.log("error at pdf bucket",error)
                resolve(null);
            });
           
        });
    }
    else {
        e.target.value = '';
    }
 
}